import axios from 'axios';
import { addSnackbar } from '../shared/components/snackbar/snackbarReducer';
import { updateManualClaimStatus } from '../manualClaim/rootReducer';
import { fgManualClaims, fgRepricing, getPlanName, tpaGroups } from '../shared/globals';
import {
  claimStatusCodes,
  filterGroups,
  isStatusNew,
  isStatusResearch,
  isStatusClinical,
} from '../shared/status';
import { validArray } from '../shared/typeChecks';

export const GET_CLAIM = 'GET_CLAIM';
export const GET_CLAIM_PENDING = `${GET_CLAIM}_PENDING`;
export const GET_CLAIM_FULFILLED = `${GET_CLAIM}_FULFILLED`;
export const GET_CLAIM_REJECTED = `${GET_CLAIM}_REJECTED`;
export const GET_CLAIM_CANCELLED = `${GET_CLAIM}_CANCELLED`;

export const GET_POSSIBLE_PLAN = 'GET_POSSIBLE_PLAN';
export const GET_POSSIBLE_PLAN_PENDING = `${GET_POSSIBLE_PLAN}_PENDING`;
export const GET_POSSIBLE_PLAN_FULFILLED = `${GET_POSSIBLE_PLAN}_FULFILLED`;
export const GET_POSSIBLE_PLAN_REJECTED = `${GET_POSSIBLE_PLAN}_REJECTED`;
export const GET_POSSIBLE_PLAN_CANCELLED = `${GET_POSSIBLE_PLAN}_CANCELLED`;

export const GET_CLAIMS = 'GET_CLAIMS';
export const GET_CLAIMS_PENDING = `${GET_CLAIMS}_PENDING`;
export const GET_CLAIMS_FULFILLED = `${GET_CLAIMS}_FULFILLED`;
export const GET_CLAIMS_REJECTED = `${GET_CLAIMS}_REJECTED`;
export const GET_CLAIMS_CANCELLED = `${GET_CLAIMS}_CANCELLED`;

export const SEARCH_GLOBAL = 'SEARCH_GLOBAL';
export const SEARCH_GLOBAL_PENDING = `${SEARCH_GLOBAL}_PENDING`;
export const SEARCH_GLOBAL_FULFILLED = `${SEARCH_GLOBAL}_FULFILLED`;
export const SEARCH_GLOBAL_REJECTED = `${SEARCH_GLOBAL}_REJECTED`;
export const SEARCH_GLOBAL_CANCELLED = `${SEARCH_GLOBAL}_CANCELLED`;

export const SEARCH_QUEUE = 'SEARCH_QUEUE';
export const SEARCH_QUEUE_PENDING = `${SEARCH_QUEUE}_PENDING`;
export const SEARCH_QUEUE_FULFILLED = `${SEARCH_QUEUE}_FULFILLED`;
export const SEARCH_QUEUE_REJECTED = `${SEARCH_QUEUE}_REJECTED`;
export const SEARCH_QUEUE_CANCELLED = `${SEARCH_QUEUE}_CANCELLED`;

export const SET_SEARCH_SCOPE = 'SET_SEARCH_SCOPE';
export const SET_TPA_GROUP = 'SET_TPA_GROUP';
export const TOGGLE_CERTIFICATION_SCORE = 'TOGGLE_CERTIFICATION_SCORE';

export const UPDATE_CLAIM = 'UPDATE_CLAIM';
export const UPDATE_CLAIM_PENDING = `${UPDATE_CLAIM}_PENDING`;
export const UPDATE_CLAIM_FULFILLED = `${UPDATE_CLAIM}_FULFILLED`;
export const UPDATE_CLAIM_REJECTED = `${UPDATE_CLAIM}_REJECTED`;
export const UPDATE_CLAIM_EXTERNALCOMMENTS = 'UPDATE_CLAIM_EXTERNALCOMMENTS';
export const UPDATE_CLAIM_EXTERNALCOMMENTS_FULFILLED = `${UPDATE_CLAIM_EXTERNALCOMMENTS}_FULFILLED`;
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const UPDATE_STATUS_PENDING = `${UPDATE_STATUS}_PENDING`;
export const UPDATE_STATUS_FULFILLED = `${UPDATE_STATUS}_FULFILLED`;
export const UPDATE_STATUS_REJECTED = `${UPDATE_STATUS}_REJECTED`;

export const UPDATE_FORMULA_TYPE = 'UPDATE_FORMULA_TYPE';
export const UPDATE_FORMULA_TYPE_PENDING = `${UPDATE_FORMULA_TYPE}_PENDING`;
export const UPDATE_FORMULA_TYPE_FULFILLED = `${UPDATE_FORMULA_TYPE}_FULFILLED`;
export const UPDATE_FORMULA_TYPE_REJECTED = `${UPDATE_FORMULA_TYPE}_REJECTED`;

export const UPDATE_REPRICING_METHODOLOGY = 'UPDATE_REPRICING_METHODOLOGY';
export const UPDATE_REPRICING_METHODOLOGY_PENDING = `${UPDATE_REPRICING_METHODOLOGY}_PENDING`;
export const UPDATE_REPRICING_METHODOLOGY_FULFILLED = `${UPDATE_REPRICING_METHODOLOGY}_FULFILLED`;
export const UPDATE_REPRICING_METHODOLOGY_REJECTED = `${UPDATE_REPRICING_METHODOLOGY}_REJECTED`;

export const UPDATE_REPRICING = 'UPDATE_REPRICING';
export const UPDATE_REPRICING_PENDING = `${UPDATE_REPRICING}_PENDING`;
export const UPDATE_REPRICING_FULFILLED = `${UPDATE_REPRICING}_FULFILLED`;
export const UPDATE_REPRICING_REJECTED = `${UPDATE_REPRICING}_REJECTED`;

export const UPDATE_QPA_ZIP_CODE = 'UPDATE_QPA_ZIP_CODE';
export const UPDATE_QPA_ZIP_CODE_PENDING = `${UPDATE_QPA_ZIP_CODE}_PENDING`;
export const UPDATE_QPA_ZIP_CODE_FULFILLED = `${UPDATE_QPA_ZIP_CODE}_FULFILLED`;
export const UPDATE_QPA_ZIP_CODE_REJECTED = `${UPDATE_QPA_ZIP_CODE}_REJECTED`;

export const GET_CODE_INFO = 'GET_CODE_INFO';
export const GET_CODE_INFO_PENDING = `${GET_CODE_INFO}_PENDING`;
export const GET_CODE_INFO_FULFILLED = `${GET_CODE_INFO}_FULFILLED`;
export const GET_CODE_INFO_REJECTED = `${GET_CODE_INFO}_REJECTED`;

export const GET_CONTRACT_PLAN_INFO = 'GET_CONTRACT_PLAN_INFO';
export const GET_CONTRACT_PLAN_INFO_PENDING = `${GET_CONTRACT_PLAN_INFO}_PENDING`;
export const GET_CONTRACT_PLAN_INFO_FULFILLED = `${GET_CONTRACT_PLAN_INFO}_FULFILLED`;
export const GET_CONTRACT_PLAN_INFO_REJECTED = `${GET_CONTRACT_PLAN_INFO}_REJECTED`;

export const GET_PLANS = 'GET_PLANS';
export const GET_PLANS_PENDING = `${GET_PLANS}_PENDING`;
export const GET_PLANS_FULFILLED = `${GET_PLANS}_FULFILLED`;
export const GET_PLANS_REJECTED = `${GET_PLANS}_REJECTED`;

export const UPDATE_PLAN = 'UPDATE_PLAN';

export const UPDATE_SHOW_ALL_VALIDATION = 'SHOW_ALL_VALIDATION';

export const EXPORT_CLAIM = 'EXPORT_CLAIM';
export const EXPORT_CLAIM_FULFILLED = `${EXPORT_CLAIM}_FULFILLED`;

export const TOGGLE_STATUS = 'TOGGLE_STATUS';
export const TOGGLE_ALL_FILTER_GROUP = 'TOGGLE_ALL_FILTER_GROUP';
export const TOGGLE_SHOW_TEST_CLAIMS = 'TOGGLE_SHOW_TEST_CLAIMS';
export const TOGGLE_SHOW_MANUAL_CLAIMS = 'TOGGLE_SHOW_MANUAL_CLAIMS';
export const TOGGLE_SHOW_NON_EXPORTED_CLAIMS = 'TOGGLE_SHOW_NON_EXPORTED_CLAIMS';

export const SET_REDIRECT = 'SET_REDIRECT';

export const UPDATE_CLAIM_SORT = 'EXPORT_CLAIM_SORT';
export const UPDATE_CLAIMS_ON_EVENTSOURCE = 'UPDATE_CLAIMS_ON_EVENTSOURCE';
export const SCRUB_SELECTED_CLAIM = 'SCRUB_SELECTED_CLAIM';
export const REMOVE_CLAIM_FROM_QUEUE = 'REMOVE_CLAIM_FROM_QUEUE';
export const MARK_CLAIM_FOR_REMOVAL = 'MARK_CLAIM_FOR_REMOVAL';
export const CLEAR_MARKED_FOR_REMOVAL = 'CLEAR_MARKED_FOR_REMOVAL';
export const SET_ANIMATE_MARKED = 'SET_ANIMATE_MARKED';

export const OPEN_CONFIRM_MODAL = 'OPEN_CONFIRM_MODAL';
export const CLOSE_CONFIRM_MODAL = 'CLOSE_CONFIRM_MODAL';

export const COPY_CLAIM = 'COPY_CLAIM';
export const COPY_CLAIM_PENDING = `${COPY_CLAIM}_PENDING`;
export const COPY_CLAIM_FULFILLED = `${COPY_CLAIM}_FULFILLED`;
export const COPY_CLAIM_REJECTED = `${COPY_CLAIM}_REJECTED`;

export const DENY_CPP_DUPLICATE = 'DENY_CPP_DUPLICATE';
export const DENY_CPP_DUPLICATE_PENDING = `${DENY_CPP_DUPLICATE}_PENDING`;
export const DENY_CPP_DUPLICATE_FULFILLED = `${DENY_CPP_DUPLICATE}_FULFILLED`;
export const DENY_CPP_DUPLICATE_REJECTED = `${DENY_CPP_DUPLICATE}_REJECTED`;

export const SELECT_CONTRACT = 'SELECT_CONTRACT';
export const SELECT_CONTRACT_PENDING = 'SELECT_CONTRACT_PENDING';
export const SELECT_CONTRACT_FULFILLED = 'SELECT_CONTRACT_FULFILLED';
export const SELECT_CONTRACT_REJECTED = 'SELECT_CONTRACT_REJECTED';

export function getClaim(id) {
  return {
    type: GET_CLAIM,
    payload: axios.get(`/api/claim/${id}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function getPossiblePlan(id) {
  return {
    type: GET_POSSIBLE_PLAN,
    payload: axios.get(`/api/claim/${id}/possiblePlans`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function setStatus(newStatus, claim, hardStop) {
  return dispatch => {
    const { status, showAllValidation, id } = claim;

    if (
      !(
        showAllValidation ||
        isStatusNew(status) ||
        isStatusResearch(status) ||
        isStatusClinical(status)
      )
    ) {
      dispatch(updateShowAllValidation(true));
      if (hardStop) {
        dispatch(addSnackbar('Please fix validation errors to continue.'));
        return;
      }
    }

    dispatch(updateStatus(id, newStatus));
  };
}

// save = v => {
//   this.props.dispatch(updateClaim(v));
// };

export function setAllExternalComments(updateInfo) {
  return (dispatch, getState) => {
    const { selectedClaim } = getState().claims;
    const services = selectedClaim.simpleClaim && selectedClaim.simpleClaim.services;
    if (needAlert(services, updateInfo.stringVal)) {
      dispatch(openConfirmModal('multiUpdate', updateInfo));
    } else {
      dispatch(multiUpdateExtNotes(updateInfo));
    }
  };
}

export function needAlert(services, newCode, contractID) {
  return (
    contractID !== 0 &&
    Array.isArray(services) &&
    services.some(service => {
      const { code = 'DEG', comment = '' } = service.repricing?.extCmnt || {};
      return (code !== newCode && code !== 'DEG') || comment !== '';
    })
  );
}

export function multiUpdateExtNotes(updateInfo) {
  return (dispatch, getState) => {
    const { selectedClaim } = getState().claims;
    dispatch(
      updateAllRepricingExternalComments({
        ...updateInfo,
        services: selectedClaim.simpleClaim?.services?.map(s => {
          return {
            ...s,
            repricing: {
              ...s.repricing,
              extCmnt: {
                code: updateInfo.stringVal,
                comment: ' ',
              },
            },
          };
        }),
      })
    );
    dispatch(updateClaim(updateInfo));
  };
}

function openConfirmModal(updateType, updateInfo) {
  return {
    type: OPEN_CONFIRM_MODAL,
    meta: {
      updateType,
      updateInfo,
    },
  };
}

export function closeConfirmModal() {
  return {
    type: CLOSE_CONFIRM_MODAL,
  };
}

export function multiUpdateAdjudication(updateInfo, services) {
  return dispatch => {
    services.forEach((service, i) => {
      if (
        updateInfo.fieldName === 'determination' &&
        updateInfo.stringVal === 'Allowed' &&
        service.adjudication &&
        service.adjudication.category === 'NotAllowed'
      ) {
        return;
      }
      dispatch(
        updateAdjudication({
          ...updateInfo,
          fieldName: `adjudication.${updateInfo.fieldName}`,
          serviceIndex: i,
          stringVal: updateInfo.stringVal,
        })
      );
    });
  };
}

export function updateAdjudication(updateInfo) {
  return dispatch => {
    dispatch(updateClaimWorker(updateInfo));

    if (updateInfo.fieldName === 'adjudication.category' && updateInfo.stringVal === 'NotAllowed') {
      dispatch(
        updateClaimWorker({
          ...updateInfo,
          fieldName: 'adjudication.determination',
          stringVal: 'Denied',
        })
      );
    }
  };
}

export function updateShareAmt(updateInfo) {
  return dispatch => {
    if (Number.isNaN(updateInfo.numberVal)) {
      dispatch(updateClaimWorker({ ...updateInfo, numberVal: undefined }));
    } else {
      dispatch(updateClaimWorker({ ...updateInfo }));
    }
  };
}

export function updatePlan(planName, sixDegPlanID) {
  return {
    type: UPDATE_PLAN,
    payload: { planName, sixDegPlanID },
  };
}

export function updatePlanInfo(
  plans,
  selectedClaim,
  researchedPolicyNum,
  researchedSixDegTPAID,
  sixDegPlanID,
  save
) {
  const planName = getPlanName(
    researchedPolicyNum,
    researchedSixDegTPAID,
    sixDegPlanID,
    plans,
    selectedClaim.earliestDt
  );
  return dispatch => {
    dispatch(updatePlan(planName, sixDegPlanID));
    const PolicyNum = researchedPolicyNum;
    const SixDegTPAID = researchedSixDegTPAID;
    const updatePlanInfo = {
      sixDegPlanID,
      SixDegTPAID,
      PolicyNum,
    };
    dispatch(updateCorrectPlanWorker(selectedClaim.id, updatePlanInfo));

    if (researchedSixDegTPAID !== undefined && selectedClaim.senderName === 'ClaimsBridge') {
      dispatch(
        updateClaimWorker({
          claimID: selectedClaim.id,
          fieldName: 'sixDegTPAID',
          numberVal: researchedSixDegTPAID,
        })
      );
    }
  };
}

export function updateClaim(updateInfo) {
  return dispatch => {
    dispatch(updateClaimWorker(updateInfo));
  };
}

export function updateQpa(zipCodeData) {
  return dispatch => {
    dispatch(updateQpaWithZipCodeWorker(zipCodeData));
  };
}

export function updateRepricing(updateInfo) {
  return dispatch => {
    dispatch(updateRepricingWorker(updateInfo));
  };
}

export function updateFormulaType(updateInfo) {
  return dispatch => {
    dispatch(updateFormula(updateInfo));
  };
}

export function updateRepricingMethodology(updateInfo) {
  return dispatch => {
    dispatch(updateRepricingMethodologyWorker(updateInfo));
  };
}

function updateFormula(updateInfo) {
  return dispatch =>
    dispatch({
      type: UPDATE_FORMULA_TYPE,
      meta: updateInfo,
      payload: axios.put(`/api/claim/${updateInfo.claimID}/formula`, updateInfo, {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }),
    });
}

function updateRepricingMethodologyWorker(updateInfo) {
  return dispatch =>
    dispatch({
      type: UPDATE_REPRICING_METHODOLOGY,
      meta: updateInfo,
      payload: axios.put(`/api/claim/${updateInfo.claimID}/repricingMethodology`, updateInfo, {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }),
    });
}

export function updateClaimOnEventSource(claimUpdate) {
  return dispatch => {
    dispatch(updateClaimStatus(claimUpdate));
    dispatch(updateClaimVisibility(claimUpdate));
  };
}

export function updateClaimVisibility(claimUpdate) {
  return (dispatch, getState) => {
    const { claims, user } = getState();
    const { selectedClaim } = claims;
    const statusVisible = claims.statusFilters[claimUpdate.status];
    const hasOwner = claimUpdate.owner && claimUpdate.owner.id !== undefined;
    const isOwner = claimUpdate.owner && claimUpdate.owner.id === user.currentUser.userID;

    if (!statusVisible || (hasOwner && !isOwner)) {
      // needs to drop off list
      if (selectedClaim && selectedClaim.id !== claimUpdate.id) {
        // not selectedClaim, should drop off immediately
        dispatch(markClaimForRemoval(claimUpdate.id));
        dispatch(setAnimateMarked());
        setTimeout(() => {
          dispatch(removeClaimFromQueue(claimUpdate.id));
          dispatch(clearMarkedForRemoval());
        }, 750);
      } else dispatch(markClaimForRemoval(claimUpdate.id)); // is selectedClaim, should drop off later
    }
  };
}

export function markClaimForRemoval(id) {
  return { type: MARK_CLAIM_FOR_REMOVAL, payload: id };
}

export function clearMarkedForRemoval() {
  return { type: CLEAR_MARKED_FOR_REMOVAL };
}

export function removeClaimFromQueue(id) {
  return { type: REMOVE_CLAIM_FROM_QUEUE, payload: id };
}

export function updateClaimStatus(claimUpdate) {
  return dispatch => {
    if (
      claimUpdate.status === claimStatusCodes.repriceNew ||
      filterGroups.manualClaims.includes(claimUpdate.status)
    ) {
      dispatch(updateManualClaimStatus(claimUpdate));
      dispatch(updateRepricingClaimsStatus(claimUpdate));
    } else {
      dispatch(updateRepricingClaimsStatus(claimUpdate));
    }
  };
}

export function updateRepricingClaimsStatus(claimUpdate) {
  return {
    type: UPDATE_CLAIMS_ON_EVENTSOURCE,
    payload: claimUpdate,
  };
}

export function updateStatus(claimID, status) {
  return {
    type: UPDATE_STATUS,
    meta: { status },
    payload: axios.put(
      `/api/claim/${claimID}/status`,
      { status },
      {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }
    ),
  };
}

export function updateClaimWorker(updateInfo) {
  // const {stringVal, boolVal, fieldName} = updateInfo;

  // const newDataNoClaimID = {
  //   stringVal,
  //   boolVal,;
  // }
  return {
    type: UPDATE_CLAIM,
    meta: updateInfo,
    payload: axios.put(`/api/claim/${updateInfo.claimID}`, updateInfo, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function updateCorrectPlanWorker(claimID, updatePlanInfo) {
  // const {stringVal, boolVal, fieldName} = updateInfo;

  // const newDataNoClaimID = {
  //   stringVal,
  //   boolVal,;
  // }
  return {
    type: UPDATE_CLAIM,
    meta: updatePlanInfo,
    payload: axios.put(`/api/claim/${claimID}/updateCorrectPlan`, updatePlanInfo, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function updateAllRepricingExternalComments(updateInfo) {
  return {
    type: UPDATE_CLAIM_EXTERNALCOMMENTS,
    meta: updateInfo,
    payload: axios.put(
      `/api/claim/${updateInfo.claimID}/setAllRepricingExternalComments`,
      { code: updateInfo.stringVal, comment: '' },
      {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }
    ),
  };
}

export function updateRepricingWorker(updateInfo) {
  return {
    type: UPDATE_REPRICING,
    meta: updateInfo,
    payload: axios.put(`/api/claim/${updateInfo.claimID}/repricing`, updateInfo, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function updateQpaWithZipCodeWorker(zipCodeData) {
  return {
    type: UPDATE_QPA_ZIP_CODE,
    meta: zipCodeData,
    payload: axios.put(`/api/qpazipcode/${zipCodeData.claimID}`, zipCodeData, {
      headers: {
        'X-CSRF-Token': localStorage.getItem('csrfToken'),
      },
      mode: 'no-cors',
      'Access-Control-Allow-Origin': '*',
    }),
  };
}

function getStatusFilter(state, filterGroup) {
  return filterGroup.filter(filter => state.statusFilters[filter] === true);
}

function getAllowedScores(roles) {
  return score => isScoreAllowed(score, roles);
}

export function isScoreAllowed(score, roles) {
  return validArray(roles).includes(`claims-certification-${score}`);
}

export function shouldAllowAllScores(group) {
  return group === fgManualClaims;
}

function getScoresFilter(scoreMap, roles, allowAllScores) {
  const scores = Object.keys(scoreMap).filter(score => scoreMap[score]);
  if (allowAllScores) return scores;

  return scores.filter(getAllowedScores(roles));
}

export function getClaims(
  group,
  pageNum,
  pageSize,
  sortBy,
  showTestClaims,
  showManualClaims,
  showNonExportedClaims
) {
  return (dispatch, getState) => {
    const { claims = {}, user } = getState();
    const roles = user && user.currentUser && user.currentUser.roles;
    const groupName = filterGroups.hasOwnProperty(group) ? group : fgRepricing;
    const statuses = getStatusFilter(claims, filterGroups[groupName]);
    const scores = getScoresFilter(
      claims.certificationScores,
      roles,
      shouldAllowAllScores(groupName)
    );
    const tpaGroup = tpaGroups.find(group => group.id === claims.selectedTPAGroup);
    const groupIDs = tpaGroup ? tpaGroup.group : [];
    let includeEmployeePlan = false;
    if (groupIDs && groupIDs.filter(x => x === 79).length > 0) {
      includeEmployeePlan = !includeEmployeePlan;
    }
    dispatch(
      getClaimsWorker(
        statuses,
        scores,
        groupName,
        groupIDs,
        includeEmployeePlan,
        pageSize,
        pageNum,
        sortBy,
        showTestClaims,
        showManualClaims,
        showNonExportedClaims
      )
    );
  };
}

function getClaimsWorker(
  statuses,
  scores,
  group,
  tpas,
  includeEmployeePlan,
  pageSize,
  pageNum,
  sortBy,
  showTestClaims,
  showManualClaims,
  showNonExportedClaims
) {
  const sendEmptyArrayIfEmployeeGroup = includeEmployeePlan ? [] : tpas;
  return {
    type: GET_CLAIMS,
    meta: { group },
    payload: axios.post(
      `/api/getClaims?pageNum=${pageNum}&pageSize=${pageSize}`,
      {
        statuses,
        scores,
        tpas: sendEmptyArrayIfEmployeeGroup,
        group,
        includeEmployeePlan,
        sortBy,
        showTestClaims,
        showManualClaims,
        showNonExportedClaims,
      },
      {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }
    ),
  };
}

export function searchGlobal(searchTerm) {
  return {
    type: SEARCH_GLOBAL,
    payload: axios.get(`/api/claimsSearch/${searchTerm}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
    meta: searchTerm,
  };
}

export function searchQueue(searchTerm) {
  return { type: SEARCH_QUEUE, meta: searchTerm };
}

export function setSearchScope(isGlobal) {
  return { type: SET_SEARCH_SCOPE, meta: isGlobal };
}

export function setSelectedTPAGroup(selectedTPAGroup) {
  return { type: SET_TPA_GROUP, meta: selectedTPAGroup };
}

export function toggleCertificationScore(score) {
  return { type: TOGGLE_CERTIFICATION_SCORE, meta: score };
}

export function toggleStatusFilter(status) {
  return { type: TOGGLE_STATUS, meta: status };
}

export function toggleAllFilterGroup(filterGroup) {
  return { type: TOGGLE_ALL_FILTER_GROUP, meta: filterGroup };
}

export function toggleShowTestClaims() {
  return { type: TOGGLE_SHOW_TEST_CLAIMS };
}

export function toggleShowManualClaims() {
  return { type: TOGGLE_SHOW_MANUAL_CLAIMS };
}

export function toggleShowNonExportedClaims(nonExportedClaims) {
  return { type: TOGGLE_SHOW_NON_EXPORTED_CLAIMS, payload: nonExportedClaims };
}

export function updateClaimSort(sortBy) {
  return { type: UPDATE_CLAIM_SORT, meta: { sortBy } };
}

export function updateShowAllValidation(showAllValidation) {
  return { type: UPDATE_SHOW_ALL_VALIDATION, payload: showAllValidation };
}

export function qpaHoverInfo(type, code, qpaHoverdata) {
  return {
    type: GET_CODE_INFO_FULFILLED,
    meta: { type, code },
    payload: { data: qpaHoverdata },
  };
}

export function exportClaim(claimID) {
  return {
    type: EXPORT_CLAIM,
    payload: axios.get(`/api/claim/${claimID}/export`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      responseType: 'blob',
    }),
    meta: claimID,
  };
}

export function scrubSelectedClaim() {
  return { type: SCRUB_SELECTED_CLAIM };
}

export function setAnimateMarked() {
  return { type: SET_ANIMATE_MARKED };
}

export function removeMarkedForRemoval() {
  return (dispatch, getState) => {
    dispatch(setAnimateMarked());
    setTimeout(() => {
      getState().claims.markedForRemoval.forEach(id => dispatch(removeClaimFromQueue(id)));
      dispatch(clearMarkedForRemoval());
    }, 750);
  };
}

export function getCodeInfo(type, code, date) {
  return {
    type: GET_CODE_INFO,
    meta: { type, code },
    payload: axios.post(
      `/api/code/${type}`,
      { date, code },
      { headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') } }
    ),
  };
}

export function getContractPlanInfo(id) {
  return {
    type: GET_CONTRACT_PLAN_INFO,
    meta: { id },
    payload: axios.get(`/api/contracts/${id}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function getPlans() {
  return {
    type: GET_PLANS,
    payload: axios.get(`/api/plans`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function takeDuplicateAction(worker, sourceID) {
  return (dispatch, getState) => {
    const { selectedClaim } = getState().claims;
    dispatch(worker(selectedClaim.id, sourceID)).then(res => {
      dispatch(addSnackbar(res.value.data.message));
    });
  };
}

export function copyClaimWorker(targetID, sourceID) {
  return {
    type: COPY_CLAIM,
    payload: axios.put(
      `/api/copyClaim/${sourceID}/into/${targetID}`,
      {},
      {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }
    ),
  };
}

export function denyCPPDuplicateClaimWorker(selectedClaim) {
  return {
    type: DENY_CPP_DUPLICATE,
    payload: axios.put(
      `/api/denyCPPDuplicate/${selectedClaim}`,
      {},
      {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }
    ),
  };
}

export function getNavigateID(claimList, selectedID, offset) {
  if (!Array.isArray(claimList) || claimList.length === 0) {
    return '';
  }
  const index = claimList.findIndex(c => c.id === selectedID);
  if (index < 0) {
    return claimList[0].id;
  }
  const nextIndex = index + offset;
  if (nextIndex >= 0 && nextIndex < claimList.length) {
    return claimList[nextIndex].id;
  }
  return selectedID;
}

export const navigateQueue = (claimList, selectedID, baseRoute, offset, history) => () => {
  removeMarkedForRemoval();
  const navigateID = getNavigateID(claimList, selectedID, offset);
  history.push(`/${baseRoute || fgRepricing}/${navigateID}`);
  const listItem = document.getElementById(`claim-list-item-${navigateID}`);
  if (listItem) listItem.focus();
};

export function updateClaimContract(claimID, contractID, providerGroup, contractType) {
  return (dispatch, getState) => {
    const { selectedClaim } = getState().claims;
    const { services } = selectedClaim.simpleClaim;
    if (needAlert(services, 'CON', contractID) || needAlert(services, 'SCA', contractID)) {
      dispatch(
        openConfirmModal('selectContract', { claimID, contractID, providerGroup, contractType })
      );
      return;
    }

    dispatch(selectContract({ claimID, contractID, providerGroup, contractType }));
  };
}

export function selectContract({ claimID, contractID, providerGroup, contractType }) {
  return {
    type: SELECT_CONTRACT,
    meta: { claimID, contractID, providerGroup, contractType },
    payload: axios.put(
      `/api/claimContract/${claimID}`,
      { contractID, providerGroup, contractType },
      {
        headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
      }
    ),
  };
}
