import React, { FormEventHandler, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Plan } from '../../edi/planEditor/types/plans';
import { Plan as PlanOptionProps } from '../../repricing/types/repricingTypes';
import AutocompleteSelect, { PlanOption } from '../../shared/components/AutocompleteSelect';

const useStyles = makeStyles({
  input: {
    margin: '0 0.5rem 0.5rem 0',
    width: 500,
  },
});

type Props = {
  allPlans: Record<number, Plan>;
  className?: string;
  selectedSixDegTPAID?: number;
  onChange: (sixDegPlanID?: number) => void;
};

function plansToPlanOptions(
  plans: Record<number, Plan>,
  selectedSixDegTPAID?: number
): PlanOptionProps[] {
  const planOptions: PlanOptionProps[] = [];
  for (const key in plans) {
    if (key === '0') continue;
    if (selectedSixDegTPAID && plans[key].sixDegTPAID !== selectedSixDegTPAID) continue;
    if (Object(plans).hasOwnProperty(key)) {
      planOptions.push({
        planName: plans[key].planName,
        policyNum: plans[key].planID,
        sixDegPlanID: plans[key].sixDegPlanID,
        sixDegTPAID: plans[key].sixDegTPAID,
      });
    }
  }
  return planOptions;
}

function searchPlans(plans: PlanOptionProps[], searchText: string): PlanOptionProps[] {
  return plans.filter(
    plan =>
      plan.planName.toLowerCase().includes(searchText.toLowerCase()) ||
      plan.policyNum.toLowerCase().includes(searchText.toLowerCase()) ||
      plan.sixDegPlanID.toString().includes(searchText)
  );
}

export default function PlanSelect({
  allPlans,
  onChange,
  selectedSixDegTPAID,
}: Props): JSX.Element {
  const classes = useStyles();
  const allPlanOptions = plansToPlanOptions(allPlans, selectedSixDegTPAID);
  const [input, setInput] = useState({
    value: '',
    isDropdownOpen: false,
    selectedOption: undefined as PlanOptionProps | undefined,
    matchingOptions: allPlanOptions,
  });

  useEffect(() => {
    const matchingOptions = plansToPlanOptions(allPlans, selectedSixDegTPAID);
    if (
      selectedSixDegTPAID === undefined ||
      input.selectedOption == null ||
      input.selectedOption.sixDegTPAID === selectedSixDegTPAID
    )
      setInput(state => ({ ...state, matchingOptions }));
    else
      setInput({
        value: '',
        isDropdownOpen: false,
        selectedOption: undefined as PlanOptionProps | undefined,
        matchingOptions,
      });
  }, [allPlans, selectedSixDegTPAID, input.selectedOption]);

  const handleInputValueChange: FormEventHandler<HTMLInputElement> = e => {
    const { value } = e.currentTarget;
    setInput(state => ({
      ...state,
      value,
      matchingOptions: searchPlans(allPlanOptions, value),
    }));
  };

  const handleSelect = (plan?: PlanOptionProps): void => {
    setInput({
      value: plan?.planName || '',
      isDropdownOpen: false,
      selectedOption: plan,
      matchingOptions: plan ? [plan] : allPlanOptions,
    });
    onChange(plan?.sixDegPlanID);
  };

  const handleBlur = (): void =>
    setInput(state => ({
      ...state,
      value: state.selectedOption?.planName || '',
      isDropdownOpen: false,
    }));

  const handleClick = (): void => setInput(state => ({ ...state, isDropdownOpen: true }));

  return (
    <AutocompleteSelect
      compact
      className={classes.input}
      inputValue={input.value}
      onSelect={handleSelect}
      onChange={handleInputValueChange}
      onBlur={handleBlur}
      onClick={handleClick}
      label="Plan"
      placeholder="Search by plan name or policy number"
      isOpen={input.isDropdownOpen}
      options={input.matchingOptions}
      RenderComponent={PlanOption}
    />
  );
}
