import React, { useState, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Typography from '@material-ui/core/Typography';
import { colors } from '@apps/shared/src/style';

import TextInput from '../../shared/components/TextInput';
import DateInput from '../../manualClaim/DateInput';
import { validArray } from '../../shared/typeChecks';
import { checkDate } from '../../shared/validators';
import { PlanMultiplier, PlanMultiplierField } from './types/plans';
import { PlanActionTypes } from './types/actions';
import { zeroDate, infinityDate } from './plansReducer';

const useStyles = makeStyles({
  formContainer: {
    marginTop: '15px',
  },
  smallInput: {
    maxWidth: '100px',
    marginRight: '12px',
  },
  addButton: {
    backgroundColor: colors.green,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.greenMuted,
    },
    padding: '5px',
    margin: '5px',
    minWidth: '0px',
  },
  formHeader: {
    marginTop: '20px',
    marginBottom: '15px',
  },
  alertText: {
    color: colors.red,
    fontSize: '12px',
    textAlign: 'left',
  },
});

function defaultPlanMultiplier(): PlanMultiplier {
  return {
    sixDegMultiplierID: 0,
    sixDegPlanID: 0,
    startDate: zeroDate,
    endDate: infinityDate,
    facilityMultiplier: '',
    professionalMultiplier: '',
    drugMultiplier: '',
    anesthesiaMultiplier: '',
  };
}

export function stripMultiplier(planMultiplier: PlanMultiplier): PlanMultiplier {
  const strippedMultiplier = { ...planMultiplier };
  if (strippedMultiplier.facilityMultiplier === '') {
    delete strippedMultiplier.facilityMultiplier;
  }
  if (strippedMultiplier.professionalMultiplier === '') {
    delete strippedMultiplier.professionalMultiplier;
  }
  if (strippedMultiplier.drugMultiplier === '') {
    delete strippedMultiplier.drugMultiplier;
  }
  if (strippedMultiplier.anesthesiaMultiplier === '') {
    delete strippedMultiplier.anesthesiaMultiplier;
  }
  return strippedMultiplier;
}

export function updateMultiplierDate(
  currentPlanMultiplier: PlanMultiplier,
  field: 'startDate' | 'endDate',
  value: number | string,
  handleSnackBar: (message: string) => void
): PlanMultiplier {
  const newPlanMultiplier = { ...currentPlanMultiplier };
  const [isValid, parsedDate] = checkDate(value, true);
  newPlanMultiplier[field] = parsedDate;

  if (isValid) return newPlanMultiplier;

  newPlanMultiplier[field] = field === 'endDate' ? infinityDate : zeroDate;
  if (value !== newPlanMultiplier[field]) handleSnackBar(`Invalid Date: ${value}`);

  return newPlanMultiplier;
}

type ParentProps = {
  planMultipliers: PlanMultiplier[];
  sixDegPlanID: number;
  updatePlanMultipliers: (
    planMultipliers: PlanMultiplier[],
    sixDegPlanID: number
  ) => PlanActionTypes;
  handleSnackBar: (message: string) => void;
};

type Props = ParentProps;

export default function PlanMultiplierForm({
  planMultipliers,
  sixDegPlanID,
  updatePlanMultipliers,
  handleSnackBar,
}: Props): JSX.Element {
  const classes = useStyles();
  const validPlanMultipliers = validArray(planMultipliers);

  const [planMultiplier, setPlanMultiplier] = useState(defaultPlanMultiplier());
  const [errorText, setErrorText] = useState('');

  const handleMultiplierDateUpdate =
    (field: 'startDate' | 'endDate') =>
    (value: number | string): void => {
      const newPlanMultiplier = updateMultiplierDate(planMultiplier, field, value, handleSnackBar);
      setErrorText('');
      setPlanMultiplier(newPlanMultiplier);
    };

  const handleMultiplierFloatUpdate =
    (field: PlanMultiplierField) =>
    (e: ChangeEvent<HTMLInputElement>): void => {
      const { value } = e.target;
      const newPlanMultiplier = { ...planMultiplier };

      newPlanMultiplier[field] = parseFloat(value);

      setPlanMultiplier(newPlanMultiplier);
    };

  const handleAddClick = (): void => {
    if (isNotUniqueStartDate) {
      setErrorText('Two plan multipliers cannot have the same Start Date');
      return;
    }
    const newPlanMultipliers = [...validPlanMultipliers];
    newPlanMultipliers.push(stripMultiplier(planMultiplier));
    updatePlanMultipliers(newPlanMultipliers, sixDegPlanID);
    setPlanMultiplier(defaultPlanMultiplier());
  };

  const isNotUniqueStartDate = validPlanMultipliers.some(
    ({ startDate }) => startDate === planMultiplier.startDate
  );

  return (
    <div className={classes.formContainer}>
      <Typography className={classes.formHeader}>Add Multiplier:</Typography>

      <DateInput
        className={classes.smallInput}
        name="startDate"
        label="Start Date"
        aria-label="Start Date"
        onValueChange={handleMultiplierDateUpdate('startDate')}
        value={planMultiplier.startDate}
      />

      <DateInput
        className={classes.smallInput}
        name="endDate"
        label="End Date"
        onValueChange={handleMultiplierDateUpdate('endDate')}
        value={planMultiplier.endDate}
      />
      <TextInput
        className={classes.smallInput}
        label="Facility"
        aria-label="Facility"
        value={planMultiplier.facilityMultiplier}
        isFloat
        onChange={handleMultiplierFloatUpdate('facilityMultiplier')}
      />
      <TextInput
        className={classes.smallInput}
        label="Professional"
        aria-label="Professional"
        value={planMultiplier.professionalMultiplier}
        isFloat
        onChange={handleMultiplierFloatUpdate('professionalMultiplier')}
      />
      <TextInput
        className={classes.smallInput}
        label="Drug"
        aria-label="Drug"
        value={planMultiplier.drugMultiplier}
        isFloat
        onChange={handleMultiplierFloatUpdate('drugMultiplier')}
      />
      <TextInput
        className={classes.smallInput}
        label="Anesthesia"
        aria-label="Anesthesia"
        value={planMultiplier.anesthesiaMultiplier}
        isFloat
        onChange={handleMultiplierFloatUpdate('anesthesiaMultiplier')}
      />
      <IconButton
        className={classes.addButton}
        onClick={handleAddClick}
        data-testid="add-multiplier-button"
      >
        <AddCircleIcon />
      </IconButton>

      <Typography variant="body1" className={classes.alertText}>
        {errorText}
      </Typography>
    </div>
  );
}
