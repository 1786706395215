import userReducer from '@apps/shared/src/auth/userReducer';
import ResetSessionTimeout from '@apps/shared/src/middleware/ResetSessionTimeout';
import adminReducer from '@apps/shared/src/components/AdminUserList/adminReducer';
import registerReducer from '@apps/shared/src/register/reducer';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import contractsReducer from './contracts/contractsReducer';
import plansReducer from './edi/planEditor/plansReducer';
import manualClaimsReducer from './manualClaim/rootReducer';
import reportsReducer from './reports/reportsReducer';
import claimsReducer from './repricing/claimsReducer';
import settlementsReducer from './settlements/settlementsReducer';
import snackbarReducer from './shared/components/snackbar/snackbarReducer';

export const reducers = combineReducers({
  claims: claimsReducer,
  contracts: contractsReducer,
  manualClaim: manualClaimsReducer,
  plans: plansReducer,
  reports: reportsReducer,
  snackbars: snackbarReducer,
  user: userReducer,
  register: registerReducer,
  settlements: settlementsReducer,
  admin: adminReducer,
});

const middleware = applyMiddleware(promise, thunk, ResetSessionTimeout);
const store = createStore(reducers, composeWithDevTools(middleware));

export default store;
