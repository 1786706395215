import * as p from './types/actions';
import { PlansState, TPA, Plan } from './types/plans';

export const zeroDate = '01/01/0001';
export const infinityDate = '01/01/9999';

export const defaultPlansState: PlansState = {
  areAllPlansLoading: false,
  allPlans: {},
  allTPAs: {},
  filteredTPAs: [],
  allPlanMultipliers: {},
  allCensusNames: {},
};

const zeroTPA: TPA = {
  sixDegTPAID: 0,
  tpaName: '',
  accountID: '',
};

const zeroPlan: Plan = {
  sixDegPlanID: 0,
  sixDegTPAID: zeroTPA.sixDegTPAID,
  tpaName: zeroTPA.tpaName,
  planName: '',
  planID: '',
  stageName: '',
  startDate: '',
  termDate: infinityDate,
  indefiniteRunOut: false,
  repriceForRunOutPeriod: false,
  runOutEndDate: '',
  sfOpportunityID: '',
  isLoading: false,
  isError: false,
  percentSavings: 0,
  bypassEligibility: true,
  invoicingType: 'none',
  mediviPlanAccess: 'Full Access',
  displayMediviMessage: false,
  tbcThreshold: false,
  threshold: 0,
  facilityInvoicing: false,
  professionalInvoicing: false,
  mediviMessage: '',
  send837Invoice: false,
  groupIdOnCard: '',
  runIn: false,
};

export default (state: PlansState = defaultPlansState, action: p.PlanActionTypes): PlansState => {
  switch (action.type) {
    case p.UPDATE_PLAN_INFO: {
      const { field, value, sixDegPlanID } = action.meta;
      const { allPlans } = state;
      const newPlan = { ...allPlans[sixDegPlanID] };

      if (typeof newPlan[field] === 'number' && typeof value === 'number') {
        (newPlan[field] as number) = value;
      } else if (typeof newPlan[field] === 'string' && typeof value === 'string') {
        (newPlan[field] as string) = value;
      } else if (typeof newPlan[field] === 'boolean' && typeof value === 'boolean') {
        (newPlan[field] as boolean) = value;
        // not going to modify all plan data to include a default value for this field
      } else if (field === 'invoicingType' && !!typeof newPlan[field]) {
        newPlan.invoicingType = value as string;
      } else if (field === 'mediviPlanAccess') {
        newPlan.mediviPlanAccess = value as string;
      }

      return {
        ...state,
        allPlans: { ...allPlans, [sixDegPlanID]: newPlan },
      };
    }

    case p.UPDATE_CENSUS_NAME: {
      const { censusNames, sixDegPlanID } = action.meta;
      const { allCensusNames } = state;

      return {
        ...state,
        allCensusNames: { ...allCensusNames, [sixDegPlanID]: censusNames },
      };
    }

    case p.UPDATE_PLAN_MULTIPLIERS: {
      const { planMultipliers, sixDegPlanID } = action.meta;
      const { allPlanMultipliers } = state;

      return {
        ...state,
        allPlanMultipliers: { ...allPlanMultipliers, [sixDegPlanID]: planMultipliers },
      };
    }

    case p.GET_ALL_PLANS_PENDING: {
      return {
        ...state,
        areAllPlansLoading: true,
      };
    }

    case p.GET_ALL_PLANS_REJECTED: {
      return {
        ...state,
        areAllPlansLoading: false,
      };
    }

    case p.GET_ALL_PLANS_FULFILLED: {
      const { plans, planMultipliers, censusNames, tpas } = action.payload.data;

      return {
        ...state,
        areAllPlansLoading: false,
        allPlans: { ...plans, 0: zeroPlan },
        allPlanMultipliers: { ...planMultipliers, 0: [] },
        allCensusNames: { ...censusNames, 0: [] },
        allTPAs: { ...tpas },
        filteredTPAs: [],
      };
    }

    case p.GET_PLAN_PENDING:
    case p.SAVE_PLAN_PENDING: {
      const { sixDegPlanID } = action.meta;

      const loadingPlan = { ...state.allPlans[sixDegPlanID] };
      loadingPlan.isLoading = true;
      if (!!loadingPlan.percentSavings && !!loadingPlan.invoicingType)
        loadingPlan.send837Invoice = true;
      else loadingPlan.send837Invoice = false;

      return {
        ...state,
        allPlans: { ...state.allPlans, [sixDegPlanID]: loadingPlan },
      };
    }

    case p.GET_PLAN_REJECTED:
    case p.SAVE_PLAN_REJECTED: {
      const { sixDegPlanID } = action.meta;

      const erroredPlan = { ...state.allPlans[sixDegPlanID] };
      erroredPlan.isError = true;
      erroredPlan.isLoading = false;

      return {
        ...state,
        allPlans: { ...state.allPlans, [sixDegPlanID]: erroredPlan },
      };
    }

    case p.GET_PLAN_FULFILLED:
    case p.SAVE_PLAN_FULFILLED: {
      const { payload } = action;
      const { allPlans, allPlanMultipliers, allCensusNames } = state;
      const { plan, planMultipliers, censusNames } = payload.data;
      plan.isLoading = false;

      return {
        ...state,
        allPlans: { ...allPlans, [plan.sixDegPlanID]: plan },
        allCensusNames: { ...allCensusNames, [plan.sixDegPlanID]: censusNames },
        allPlanMultipliers: { ...allPlanMultipliers, [plan.sixDegPlanID]: planMultipliers },
      };
    }

    case p.SET_FILTERED_TPAS: {
      const { filteredTPAs } = action.meta;

      return {
        ...state,
        filteredTPAs,
      };
    }

    case p.REMOVE_NEW_PLAN_TPA: {
      return {
        ...state,
        allPlans: { ...state.allPlans, 0: zeroPlan },
        allPlanMultipliers: { ...state.allPlanMultipliers, 0: [] },
        allCensusNames: { ...state.allCensusNames, 0: [] },
      };
    }

    default:
      return state;
  }
};
