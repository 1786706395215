import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';
import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';

import PlanCard from './PlanCard';
import PlanEditorHeader from './PlanEditorHeader';

import { RootState } from '../../shared/types/types';
import { Plan, PlanMultiplier } from './types/plans';

import { getPlans } from './plansActions';

const filterPlansByTPA = (
  allPlans: Record<number, Plan>,
  selectedTPAIDs: number[]
): Record<number, Plan> => {
  const filteredPlans: Record<number, Plan> = {};

  Object.values(allPlans).forEach(plan => {
    if (selectedTPAIDs.includes(plan.sixDegTPAID)) {
      filteredPlans[plan.sixDegPlanID] = plan;
    }
  });

  return filteredPlans;
};

const useStyles = makeStyles({
  planContainer: {
    maxWidth: '1920px',
    minWidth: '800px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  loadingBuffer: {
    marginTop: '200px',
  },
});

type StateProps = {
  allPlans: Record<number, Plan>;
  allPlanMultipliers: Record<number, PlanMultiplier[]>;
  allCensusNames: Record<number, string[]>;
  areAllPlansLoading: boolean;
  filteredTPAs: number[];
};

const mapStateToProps = ({ plans }: RootState): StateProps => {
  const { allPlans, allPlanMultipliers, allCensusNames, areAllPlansLoading, filteredTPAs } = plans;
  return {
    allPlans,
    allPlanMultipliers,
    allCensusNames,
    areAllPlansLoading,
    filteredTPAs,
  };
};

const mapDispatchToProps = {
  getPlans,
};

type Props = StateProps & typeof mapDispatchToProps;

function PlanEditor({
  allPlans,
  allPlanMultipliers,
  allCensusNames,
  getPlans,
  areAllPlansLoading,
  filteredTPAs,
}: Props): JSX.Element {
  const classes = useStyles();

  useEffect(() => {
    getPlans();
  }, [getPlans]);

  const filteredPlans =
    filteredTPAs.length === 0 ? allPlans : filterPlansByTPA(allPlans, filteredTPAs);

  return (
    <>
      <LoadingSpinner isLoading={areAllPlansLoading} containerClassName={classes.loadingBuffer} />
      {!areAllPlansLoading && allPlans && (
        <div className={classes.planContainer}>
          <PlanEditorHeader />
          {Object.keys(filteredPlans)
            .sort((a, b) => {
              return parseInt(b, 10) - parseInt(a, 10);
            })
            .map(sixDegPlanID => {
              if (sixDegPlanID === '0') {
                return null;
              }

              const id = parseInt(sixDegPlanID, 10);
              return (
                <PlanCard
                  key={sixDegPlanID}
                  isNewPlan={false}
                  plan={allPlans[id]}
                  censusNames={allCensusNames[id]}
                  planMultipliers={allPlanMultipliers[id]}
                />
              );
            })}
        </div>
      )}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanEditor);
