import { AxiosPromise } from 'axios';
import { AllPlansResponse, Plan, PlanMultiplier } from './plans';

export const GET_PLAN = 'GET_PLAN';
export const DELETE_PLAN = 'DELETE_PLAN';
export const GET_PLAN_PENDING = 'GET_PLAN_PENDING';
export const GET_PLAN_FULFILLED = 'GET_PLAN_FULFILLED';
export const GET_PLAN_REJECTED = 'GET_PLAN_REJECTED';

export const GET_ALL_PLANS = 'GET_ALL_PLANS';
export const GET_ALL_PLANS_PENDING = 'GET_ALL_PLANS_PENDING';
export const GET_ALL_PLANS_FULFILLED = 'GET_ALL_PLANS_FULFILLED';
export const GET_ALL_PLANS_REJECTED = 'GET_ALL_PLANS_REJECTED';

export const SAVE_PLAN = 'SAVE_PLAN';
export const SAVE_PLAN_PENDING = 'SAVE_PLAN_PENDING';
export const SAVE_PLAN_REJECTED = 'SAVE_PLAN_REJECTED';
export const SAVE_PLAN_FULFILLED = 'SAVE_PLAN_FULFILLED';

export const UPDATE_PLAN_INFO = 'UPDATE_PLAN_INFO';
export const UPDATE_CENSUS_NAME = 'UPDATE_CENSUS_NAME';
export const UPDATE_PLAN_MULTIPLIERS = 'UPDATE_PLAN_MULTIPLIERS';
export const SET_FILTERED_TPAS = 'UPDATE_TPA_FILTER';

export const ADD_NEW_PLAN_TPA = 'ADD_NEW_PLAN_TPA';
export const REMOVE_NEW_PLAN_TPA = 'REMOVE_NEW_PLAN_TPA';

interface UpdatePlanAction {
  type: typeof UPDATE_PLAN_INFO;
  meta: {
    field: keyof Plan;
    value: number | string | boolean;
    sixDegPlanID: number;
  };
}

interface UpdateCensusNameAction {
  type: typeof UPDATE_CENSUS_NAME;
  meta: {
    censusNames: string[];
    sixDegPlanID: number;
  };
}

interface UpdatePlanMultipliersAction {
  type: typeof UPDATE_PLAN_MULTIPLIERS;
  meta: {
    planMultipliers: PlanMultiplier[];
    sixDegPlanID: number;
  };
}

interface GetPlansAction {
  type: typeof GET_ALL_PLANS;
  payload: AxiosPromise;
}

interface GetPlansPendingAction {
  type: typeof GET_ALL_PLANS_PENDING;
}

interface GetPlansFulfilledAction {
  type: typeof GET_ALL_PLANS_FULFILLED;
  payload: {
    data: AllPlansResponse;
  };
}

interface GetPlansRejectedAction {
  type: typeof GET_ALL_PLANS_REJECTED;
}

type GetPlansActions =
  | GetPlansAction
  | GetPlansPendingAction
  | GetPlansFulfilledAction
  | GetPlansRejectedAction;

interface GetPlanAction {
  type: typeof GET_PLAN;
  payload: AxiosPromise;
  meta: {
    sixDegPlanID: number;
  };
}

interface DeletePlanAction {
  type: typeof DELETE_PLAN;
  payload: AxiosPromise;
  meta: {
    sixDegPlanID: number;
  };
}

interface GetPlanPendingAction {
  type: typeof GET_PLAN_PENDING;
  meta: {
    sixDegPlanID: number;
  };
}

interface GetPlanFulfilledAction {
  type: typeof GET_PLAN_FULFILLED;
  payload: {
    data: {
      plan: Plan;
      planMultipliers: PlanMultiplier[];
      censusNames: string[];
    };
  };
}

interface GetPlanRejectedAction {
  type: typeof GET_PLAN_REJECTED;
  meta: {
    sixDegPlanID: number;
  };
}

type GetPlanActions =
  | GetPlanAction
  | GetPlanPendingAction
  | GetPlanFulfilledAction
  | GetPlanRejectedAction;

type DeletePlanActions = DeletePlanAction;

interface SavePlanAction {
  type: typeof SAVE_PLAN;
  payload: AxiosPromise;
  meta: {
    sixDegPlanID: number;
  };
}

interface SavePlanPendingAction {
  type: typeof SAVE_PLAN_PENDING;
  meta: {
    sixDegPlanID: number;
  };
}

interface SavePlanFulfilledAction {
  type: typeof SAVE_PLAN_FULFILLED;
  payload: {
    data: {
      plan: Plan;
      planMultipliers: PlanMultiplier[];
      censusNames: string[];
    };
  };
}

interface SavePlanRejectedAction {
  type: typeof SAVE_PLAN_REJECTED;
  meta: {
    sixDegPlanID: number;
  };
}

type SavePlanActions =
  | SavePlanAction
  | SavePlanPendingAction
  | SavePlanFulfilledAction
  | SavePlanRejectedAction;

interface UpdateTPAFilterAction {
  type: typeof SET_FILTERED_TPAS;
  meta: {
    filteredTPAs: number[];
  };
}

interface AddNewPlanTPA {
  type: typeof ADD_NEW_PLAN_TPA;
}

interface RemoveNewPlanTPA {
  type: typeof REMOVE_NEW_PLAN_TPA;
}

export type PlanActionTypes =
  | SavePlanActions
  | GetPlanActions
  | DeletePlanActions
  | GetPlansActions
  | UpdatePlanAction
  | UpdateCensusNameAction
  | UpdatePlanMultipliersAction
  | UpdateTPAFilterAction
  | AddNewPlanTPA
  | RemoveNewPlanTPA;
