import LoadingIndicator from '@apps/shared/src/components/LoadingIndicator';
import { inflateString } from '@apps/shared/src/inflators';
import { colors } from '@apps/shared/src/style/colors';
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import ClaimList from '../claimList/ClaimList';
import { unassignClaimOwner } from '../reports/reportsActions';
import ClaimOpenError from '../shared/components/ClaimOpenError';
import AppLayout from '../shared/components/layout/AppLayout';
import { fgRepricing, getBaseRoute } from '../shared/globals';
import { searchClaim } from '../shared/search';
import { claimStatusCodes, filterGroups } from '../shared/status';
import { validArray } from '../shared/typeChecks';
import * as types from '../shared/types/propTypes';
import ClaimDetail from './ClaimDetail';
import ClaimFooter from './ClaimFooter';
import {
  closeConfirmModal,
  exportClaim,
  getClaim,
  getClaims,
  getPlans,
  getPossiblePlan,
  multiUpdateExtNotes,
  navigateQueue,
  removeMarkedForRemoval,
  scrubSelectedClaim,
  selectContract,
  updatePlanInfo,
  updateStatus,
} from './claimsActions';
import ConfirmDialog from './ConfirmDialog';

export class Repricing extends Component {
  constructor(props) {
    super(props);
    this.scrollToRef = createRef();
    this.state = {
      currentPage: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    const { getClaim, getClaims, match, getPlans, plans, getPossiblePlan } = this.props;
    const { currentPage, pageSize } = this.state;
    console.log(this.props.sortBy);
    getClaims(
      getBaseRoute(match.path),
      currentPage,
      pageSize,
      this.props.sortBy,
      this.props.showTestClaims,
      this.props.showManualClaims,
      this.props.showNonExportedClaims
    );
    if (validArray(plans).length === 0) getPlans();
    if (match.params.id) {
      getClaim(match.params.id);
      getPossiblePlan(match.params.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedClaim,
      match,
      scrubSelectedClaim,
      markedForRemoval,
      removeMarkedForRemoval,
      getClaim,
      getClaims,
      getPossiblePlan,
      history,
      totalPages,
    } = this.props;
    const { currentPage, pageSize } = this.state;
    const prevBaseRoute = getBaseRoute(prevProps.match.path);
    const newBaseRoute = getBaseRoute(match.path);
    const pID = inflateString(match.params.id);
    const selectedID = inflateString(selectedClaim && selectedClaim.id);
    const status = inflateString(selectedClaim && selectedClaim.status);

    // Check if currentPage exceeds totalPages
    if (currentPage > totalPages && totalPages > 0) {
      // Update currentPage to totalPages
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentPage: totalPages });
      getClaims(
        newBaseRoute,
        totalPages,
        pageSize,
        this.props.sortBy,
        this.props.showTestClaims,
        this.props.showManualClaims,
        this.props.showNonExportedClaims
      );
    }

    if (prevBaseRoute !== newBaseRoute && (!selectedID || !pID)) {
      getClaims(
        newBaseRoute,
        currentPage,
        pageSize,
        this.props.sortBy,
        this.props.showTestClaims,
        this.props.showManualClaims,
        this.props.showNonExportedClaims
      );
    }

    if (!pID) {
      if (selectedID) scrubSelectedClaim();
      return;
    }

    // if status doesn't match baseRoute, redirect appropriately
    if (status && newBaseRoute === 'adjudication' && !status.includes('Adjudicate')) {
      history.push(`/repricing/${pID}`);
      getClaims(
        fgRepricing,
        currentPage,
        pageSize,
        this.props.sortBy,
        this.props.showTestClaims,
        this.props.showManualClaims,
        this.props.showNonExportedClaims
      );
      return;
    }
    if (status && newBaseRoute === fgRepricing && status.includes('Adjudicate')) {
      history.push(`/adjudication/${pID}`);
      getClaims(
        'adjudication',
        currentPage,
        pageSize,
        this.props.sortBy,
        this.props.showTestClaims,
        this.props.showManualClaims,
        this.props.showNonExportedClaims
      );
      return;
    }

    // if route changed and pID and selectedID don't match, get the new claim by pID
    if (match.url !== prevProps.match.url && pID !== selectedID) {
      getClaim(pID);
      getPossiblePlan(pID);
      if (validArray(markedForRemoval).length > 0) {
        removeMarkedForRemoval();
      }
      if (this.scrollToRef && this.scrollToRef.current) this.scrollToRef.current.scrollIntoView();
    }

    // Re-fetch claims if the page or page size changes
    if (currentPage !== prevState.currentPage || pageSize !== prevState.pageSize) {
      getClaims(
        newBaseRoute,
        currentPage,
        pageSize,
        this.props.sortBy,
        this.props.showTestClaims,
        this.props.showManualClaims,
        this.props.showNonExportedClaims
      );
    }
  }

  componentWillUnmount() {
    this.props.scrubSelectedClaim();
  }

  handleExportClick = () => this.props.exportClaim(this.props.selectedClaim.id);
  handleFlaggedClick = isAdjud => () =>
    this.props.updateStatus(
      this.props.selectedClaim.id,
      isAdjud ? claimStatusCodes.adjudicateFlagged : claimStatusCodes.flagged
    );

  handleOnHoldClick = isAdjud => () =>
    this.props.updateStatus(
      this.props.selectedClaim.id,
      isAdjud ? claimStatusCodes.adjudicateHold : claimStatusCodes.onHold
    );

  handleEditClaim = () => {
    this.props.scrubSelectedClaim();
    this.props.history.push(`/edit-claim/${this.props.selectedClaim.id}`);
  };

  handleEditPolicyNum = (policyNum, sixDegTPAID, sixDegPlanID, save) => {
    const { plans, selectedClaim } = this.props;
    this.props.updatePlanInfo(plans, selectedClaim, policyNum, sixDegTPAID, sixDegPlanID, save);
  };

  handleClaimListItemKeyPress = id => e => {
    if (e.key === 'Enter') this.handleClaimListItemSelect(id)();
  };

  handleClaimListItemSelect = id => () => {
    const baseRoute = getBaseRoute(this.props.match.path) || fgRepricing; // if current baseRoute is empty, show claim in repricing
    this.props.history.push(`/${baseRoute}/${id}`);
  };

  closeOwnershipError = () => {
    this.props.scrubSelectedClaim();
    this.props.removeMarkedForRemoval();
    this.props.history.push(`/${getBaseRoute(this.props.match.path)}`);
  };

  cancelNoteUpdate = () => {
    this.props.closeConfirmModal();
  };

  extCommentsConfirmDialogUpdate = () => {
    const { pendingNoteUpdate, multiUpdateExtNotes, selectContract, closeConfirmModal } =
      this.props;
    switch (pendingNoteUpdate.updateType) {
      case 'selectContract': {
        selectContract(pendingNoteUpdate.updateInfo);
        break;
      }
      case 'multiUpdateExtNotes':
      default: {
        multiUpdateExtNotes(pendingNoteUpdate.updateInfo);
      }
    }
    closeConfirmModal();
  };

  unassignOwner = () => {
    this.props.unassignClaimOwner(this.props.selectedClaim.id);
  };

  handlePageChange = page => {
    const newBaseRoute = getBaseRoute(this.props.match.path);
    this.setState(prevState => ({
      ...prevState,
      currentPage: page,
    }));
    this.props.getClaims(
      newBaseRoute,
      page,
      this.state.pageSize,
      this.props.sortBy,
      this.props.showTestClaims,
      this.props.showManualClaims,
      this.props.showNonExportedClaims
    );
  };

  handlePageSizeChange = size => {
    const newBaseRoute = getBaseRoute(this.props.match.path);
    this.setState(prevState => ({
      ...prevState,
      pageSize: size,
    }));
    this.props.getClaims(
      newBaseRoute,
      this.state.currentPage,
      size,
      this.props.sortBy,
      this.props.showTestClaims,
      this.props.showManualClaims,
      this.props.showNonExportedClaims
    );
  };

  render() {
    const {
      claims,
      adjudClaims,
      search,
      showTestClaims,
      showManualClaims,
      selectedClaim,
      currentUser,
      markedForRemoval,
      animateMarked,
      pendingNoteUpdate,
      match,
      history,
      isStatusUpdatePending,
    } = this.props;
    const { currentPage, pageSize } = this.state;
    const baseRoute = getBaseRoute(match.path);
    const isAdjud = baseRoute === 'adjudication';

    let claimList = isAdjud ? adjudClaims : claims;
    if (search.global) {
      claimList = search.results || [];
    } else {
      claimList = claimList.filter(claim => {
        const matchesSearch = searchClaim(search.searchTerm, claim);
        // const meetsFilterCriteria =
        //   (!showTestClaims || claim.prodOrTest === 'P') &&
        //   (!showManualClaims || claim.x12DocType === 'manual');
        return matchesSearch;
      });
    }

    const claimInList = claimList.findIndex(c => c.id === selectedClaim.id) >= 0;
    const hasClaim = Array.isArray(claimList) && claimList.length > 0 && selectedClaim;
    const isFirstClaim = !hasClaim || claimList[0].id === selectedClaim.id;
    const isLastClaim = !hasClaim || claimList[claimList.length - 1].id === selectedClaim.id;

    const isExtCommentsConfirmDialogOpen = pendingNoteUpdate !== null;

    const filterGroup = isAdjud ? filterGroups.adjudication : filterGroups.repricing;

    const handleNavigatePrev = navigateQueue(claimList, match.params.id, baseRoute, -1, history);
    const handleNavigateNext = navigateQueue(claimList, match.params.id, baseRoute, 1, history);

    return (
      <AppLayout
        sidebar={
          <ClaimList
            handleClaimListItemKeyPress={this.handleClaimListItemKeyPress}
            handleClaimListItemSelect={this.handleClaimListItemSelect}
            selectedID={this.props.match.params.id}
            animateMarked={animateMarked}
            markedForRemoval={markedForRemoval}
            claimList={claimList}
            filterGroup={filterGroup}
            filterGroupName={baseRoute}
            loading={search && search.fetchingClaims}
            handleNavigatePrev={handleNavigatePrev}
            handleNavigateNext={handleNavigateNext}
            isFirstClaim={isFirstClaim}
            isLastClaim={isLastClaim}
            allowSearch
            onPageChange={this.handlePageChange}
            onPageSizeChange={this.handlePageSizeChange}
            currentPage={currentPage}
            pageSize={pageSize}
            totalPages={this.props.totalPages}
          />
        }
        contentFooter={
          <ClaimFooter
            selectedClaim={selectedClaim}
            handleExportClick={this.handleExportClick}
            handleFlaggedClick={this.handleFlaggedClick(isAdjud)}
            handleOnHoldClick={this.handleOnHoldClick(isAdjud)}
            handleEditClaim={this.handleEditClaim}
            handleNavigatePrev={handleNavigatePrev}
            handleNavigateNext={handleNavigateNext}
            claimInList={claimInList}
            isFirstClaim={isFirstClaim}
            isLastClaim={isLastClaim}
            isAdjud={isAdjud}
            isStatusUpdatePending={isStatusUpdatePending}
          />
        }
      >
        {search && search.fetchingClaims ? (
          <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
            <LoadingIndicator
              isLoading={search && search.fetchingClaims}
              size={75}
              color={colors.orange}
            />
          </div>
        ) : (
          <div ref={this.scrollToRef}>
            <ConfirmDialog
              isOpen={isExtCommentsConfirmDialogOpen}
              label="Overwrite Warning"
              contentText="Overwrite this claim's existing external comments?"
              onCancel={this.cancelNoteUpdate}
              onConfirm={this.extCommentsConfirmDialogUpdate}
            />
            <ClaimOpenError
              currentUser={currentUser}
              selectedClaim={selectedClaim}
              handleNavigatePrev={handleNavigatePrev}
              handleNavigateNext={handleNavigateNext}
              isFirstClaim={isFirstClaim}
              isLastClaim={isLastClaim}
              claimInList={claimInList}
              closeOwnershipError={this.closeOwnershipError}
              unassignOwner={this.unassignOwner}
            />
            <ClaimDetail
              selectedClaim={selectedClaim}
              handleEditClaim={this.handleEditClaim}
              urlId={this.props.match.params.id}
              isAdjud={isAdjud}
              handleEditPolicyNum={this.handleEditPolicyNum}
            />
          </div>
        )}
      </AppLayout>
    );
  }
}

Repricing.defaultProps = {
  claims: [],
  totalPages: 0,
  adjudClaims: [],
  search: {},
  showTestClaims: false,
  showManualClaims: false,
  showNonExportedClaims: false,
  selectedClaim: undefined,
  currentUser: undefined,
  animateMarked: false,
  plans: [],
  selectedPlan: [],
  pendingNoteUpdate: null,
};
Repricing.propTypes = {
  claims: PropTypes.arrayOf(types.claim),
  totalPages: PropTypes.number,
  adjudClaims: PropTypes.arrayOf(types.claim),
  search: types.search,
  showTestClaims: PropTypes.bool,
  showManualClaims: PropTypes.bool,
  showNonExportedClaims: PropTypes.bool,
  sortBy: PropTypes.string,
  selectedClaim: types.claim,
  currentUser: types.simpleUser,
  markedForRemoval: PropTypes.arrayOf(PropTypes.string).isRequired,
  animateMarked: PropTypes.bool,
  plans: PropTypes.arrayOf(types.planInfo),
  selectedPlan: PropTypes.arrayOf(types.planInfo),
  pendingNoteUpdate: types.saveMetadata,
  history: types.history.isRequired,
  match: types.routerMatch.isRequired,
  isStatusUpdatePending: PropTypes.bool.isRequired,
  // mapDispatchToProps //
  scrubSelectedClaim: PropTypes.func.isRequired,
  removeMarkedForRemoval: PropTypes.func.isRequired,
  getClaim: PropTypes.func.isRequired,
  getClaims: PropTypes.func.isRequired,
  exportClaim: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  getPlans: PropTypes.func.isRequired,
  getPossiblePlan: PropTypes.func.isRequired,
  closeConfirmModal: PropTypes.func.isRequired,
  multiUpdateExtNotes: PropTypes.func.isRequired,
  updatePlanInfo: PropTypes.func.isRequired,
  unassignClaimOwner: PropTypes.func.isRequired,
  selectContract: PropTypes.func.isRequired,
};

function mapStateToProps({ claims, user }) {
  return {
    claims: claims.claims,
    totalPages: claims.totalPages,
    adjudClaims: claims.adjudication,
    search: claims.search,
    showTestClaims: claims.showTestClaims,
    showManualClaims: claims.showManualClaims,
    showNonExportedClaims: claims.showNonExportedClaims,
    sortBy: claims.sortBy,
    selectedClaim: claims.selectedClaim,
    dupes: claims.dupes,
    dupeErr: claims.dupeErr,
    contractErr: claims.contractErr,
    currentUser: user.currentUser,
    markedForRemoval: claims.markedForRemoval,
    animateMarked: claims.animateMarked,
    plans: claims.plans,
    selectedPlan: claims.selectedPlan,
    pendingNoteUpdate: claims.pendingNoteUpdate,
    isStatusUpdatePending: claims.isStatusUpdatePending,
  };
}

const mapDispatchToProps = {
  scrubSelectedClaim,
  removeMarkedForRemoval,
  getClaim,
  getClaims,
  getPossiblePlan,
  exportClaim,
  updateStatus,
  getPlans,
  closeConfirmModal,
  multiUpdateExtNotes,
  updatePlanInfo,
  unassignClaimOwner,
  selectContract,
};

export default connect(mapStateToProps, mapDispatchToProps)(Repricing);
