import axios from 'axios';
import { removeClaimFromQueue } from '../repricing/claimsActions';
import { updateSelectedClaim } from '../repricing/claimsReducer';
import childReducer from '../shared/childReducer';
import { addSnackbar } from '../shared/components/snackbar/snackbarReducer';
import { claimStatusCodes } from '../shared/status';
import claimItemReducer, { claimsReducer, newClaim } from './claimsReducer';
import individualOrOrgReducer, { newPersonOrOrg } from './indivOrOrgReducer';
import providerReducer, { newProvider } from './providerReducer';
import serviceLocationReducer from './serviceLocationReducer';
import servicesReducer from './servicesReducer';

export const GET_FLATCLAIM = 'GET_FLATCLAIM';
export const GET_FLATCLAIM_PENDING = `${GET_FLATCLAIM}_PENDING`;
export const GET_FLATCLAIM_FULFILLED = `${GET_FLATCLAIM}_FULFILLED`;
export const GET_FLATCLAIM_REJECTED = `${GET_FLATCLAIM}_REJECTED`;
export const GET_FLATCLAIM_CANCELLED = `${GET_FLATCLAIM}_CANCELLED`;

export const CREATE_CLAIM = 'CREATE_CLAIM';
export const CREATE_CLAIM_FULFILLED = `${CREATE_CLAIM}_FULFILLED`;
export const CREATE_CLAIM_REJECTED = `${CREATE_CLAIM}_REJECTED`;

export const UPDATE_FLATCLAIM = 'UPDATE_FLATCLAIM';
export const UPDATE_FLATCLAIM_PENDING = `${UPDATE_FLATCLAIM}_PENDING`;
export const UPDATE_FLATCLAIM_FULFILLED = `${UPDATE_FLATCLAIM}_FULFILLED`;
export const UPDATE_FLATCLAIM_REJECTED = `${UPDATE_FLATCLAIM}_REJECTED`;
export const UPDATE_FLATCLAIM_CANCELLED = `${UPDATE_FLATCLAIM}_CANCELLED`;

export const DELETE_CLAIM = 'DELETE_CLAIM';
export const DELETE_CLAIM_PENDING = `${DELETE_CLAIM}_PENDING`;
export const DELETE_CLAIM_FULFILLED = `${DELETE_CLAIM}_FULFILLED`;
export const DELETE_CLAIM_REJECTED = `${DELETE_CLAIM}_REJECTED`;
export const DELETE_CLAIM_CANCELLED = `${DELETE_CLAIM}_CANCELLED`;

export const VALIDATE_CLAIM = 'VALIDATE_CLAIM';
export const VALIDATE_CLAIM_PENDING = `${VALIDATE_CLAIM}_PENDING`;
export const VALIDATE_CLAIM_FULFILLED = `${VALIDATE_CLAIM}_FULFILLED`;
export const VALIDATE_CLAIM_REJECTED = `${VALIDATE_CLAIM}_REJECTED`;
export const VALIDATE_CLAIM_CANCELLED = `${VALIDATE_CLAIM}_CANCELLED`;

export const INVALIDATE_CLAIM = 'INVALIDATE_CLAIM';
export const INVALIDATE_CLAIM_PENDING = `${INVALIDATE_CLAIM}_PENDING`;
export const INVALIDATE_CLAIM_FULFILLED = `${INVALIDATE_CLAIM}_FULFILLED`;
export const INVALIDATE_CLAIM_REJECTED = `${INVALIDATE_CLAIM}_REJECTED`;
export const INVALIDATE_CLAIM_CANCELLED = `${INVALIDATE_CLAIM}_CANCELLED`;

export const CLAIM_ENTRY_ERROR = 'CLAIM_ENTRY_ERROR';
export const CLAIM_ENTRY_ERROR_PENDING = `${CLAIM_ENTRY_ERROR}_PENDING`;
export const CLAIM_ENTRY_ERROR_FULFILLED = `${CLAIM_ENTRY_ERROR}_FULFILLED`;
export const CLAIM_ENTRY_ERROR_REJECTED = `${CLAIM_ENTRY_ERROR}_REJECTED`;
export const CLAIM_ENTRY_ERROR_CANCELLED = `${CLAIM_ENTRY_ERROR}_CANCELLED`;

export const UPDATE_VALUE = 'UPDATE_VALUE';
export const VALIDATE = 'VALIDATE';
export const UPDATE_MANUAL_CLAIM_ON_EVENTSOURCE = 'UPDATE_MANUAL_CLAIM_ON_EVENTSOURCE';

export const CLEAR_CLAIM = 'CLEAR_CLAIM';

export function getFlatClaim(id) {
  return {
    type: GET_FLATCLAIM,
    payload: axios.get(`/api/flatClaim/${id}?edit=true`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function createClaim(createInfo) {
  return {
    type: CREATE_CLAIM,
    payload: axios.post('/api/createClaim', createInfo, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function saveClaimAndRedirect(id, claim, history, redirect) {
  return (dispatch, getState) => {
    dispatch({ type: VALIDATE });
    if (hasErrors(getState().manualClaim.selectedClaim)) {
      dispatch(addSnackbar('Ensure form is filled out completely'));
      return;
    }
    dispatch(id === undefined ? createClaim(claim) : updateFlatClaim(id, claim)).then(result => {
      if (
        result.action.type !== CREATE_CLAIM_FULFILLED &&
        result.action.type !== UPDATE_FLATCLAIM_FULFILLED
      )
        return;
      dispatch(addSnackbar('Claim successfully saved'));

      let newId = id;
      if (id === undefined) {
        newId = result.value.data.id;
      }

      if (history !== undefined) {
        // existence of history proves we want to redirect
        if (redirect === 'new-claim' || redirect === '' || redirect === undefined) {
          history.push('/new-claim');
          dispatch(clearClaim());
        } else history.push(`/${redirect}/${newId}`);
      }
    });
  };
}

export function saveClaimAndSubmit(id, claim, history, redirect) {
  return dispatch => {
    dispatch(
      saveClaimAndRedirect(id, { ...claim, status: claimStatusCodes.repriceNew }, history, redirect)
    );
  };
}

export function saveClaim(id, claim) {
  return saveClaimAndRedirect(id, { ...claim, status: undefined });
}

export function initialSaveClaim(id, claim, history) {
  return saveClaimAndRedirect(
    id,
    { ...claim, status: claimStatusCodes.dataEntry },
    history,
    'edit-claim'
  );
}

export function checkClaimAndRedirect(id, history, action) {
  return dispatch => {
    switch (action) {
      case 'validate': {
        dispatch(validateClaim(id)).then(() => history.push('/new-claim'));
        break;
      }
      case 'invalidate': {
        dispatch(invalidateClaim(id)).then(() => history.push('/new-claim'));
        break;
      }
      default: {
        dispatch(addSnackbar('Validation action unrecognized'));
        break;
      }
    }
  };
}

export function validateClaim(id) {
  return {
    type: VALIDATE_CLAIM,
    payload: axios.put(`/api/validateClaim/${id}`, id, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function invalidateClaim(id) {
  return {
    type: INVALIDATE_CLAIM,
    payload: axios.put(`/api/invalidateClaim/${id}`, id, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

function hasErrors(claim) {
  const { billProv, claims } = claim;
  return (
    Object.keys(billProv.errors).some(i => billProv.errors[i] !== '') ||
    claims.some(
      i =>
        Object.keys(i.errors).some(e => i.errors[e] !== '') ||
        Object.keys(i.patient.errors).some(e => i.patient.errors[e] !== '') ||
        Object.keys(i.subscriber.errors).some(e => i.subscriber.errors[e] !== '') ||
        i.services.some(j => Object.keys(j.errors).some(e => j.errors[e] !== ''))
    )
  );
}

export function updateFlatClaim(id, claim) {
  return {
    type: UPDATE_FLATCLAIM,
    payload: axios.put(`/api/flatClaim/${id}`, claim, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function clearClaim() {
  return { type: CLEAR_CLAIM };
}

export function deleteClaim(id) {
  return dispatch => {
    dispatch(deleteAction(id)).then(result => {
      if (result.action.type === DELETE_CLAIM_FULFILLED) {
        dispatch(addSnackbar('Claim successfully deleted'));
        dispatch(clearClaim());
        dispatch(removeClaimFromQueue(id));
      }
    });
  };
}

export function deleteAction(id) {
  return {
    type: DELETE_CLAIM,
    payload: axios.delete(`/api/claim/${id}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function populateResubmissionCode(typeOfBill) {
  if (typeOfBill.length !== 3) return '';
  return typeOfBill.charAt(2) === '1' ? '' : typeOfBill.charAt(2);
}

const nameToProviderMap = {
  billProv: providerReducer,
  patient: individualOrOrgReducer,
  subscriber: individualOrOrgReducer,
  claims: claimItemReducer,
  services: servicesReducer,
  serviceLocation: serviceLocationReducer,
};

export const newSelectedClaim = () => {
  return {
    selectedClaim: {
      billProv: newProvider(),
      claims: [newClaim()],
    },
    isEdit: false,
    invalidClaimId: false,
  };
};

export function updateManualClaimStatus(claimUpdate) {
  return {
    type: UPDATE_MANUAL_CLAIM_ON_EVENTSOURCE,
    payload: claimUpdate,
  };
}

export const rootReducer = (state = newSelectedClaim(), action) => {
  switch (action.type) {
    case VALIDATE:
      return {
        ...state,
        selectedClaim: {
          ...state.selectedClaim,
          claims: claimsReducer(state.selectedClaim.claims, { type: VALIDATE }),
          billProv: providerReducer(state.selectedClaim.billProv, { type: VALIDATE }),
          serviceLocation: serviceLocationReducer(state.selectedClaim.serviceLocation, {
            type: VALIDATE,
          }),
        },
      };

    case CLEAR_CLAIM:
      return newSelectedClaim();

    case GET_FLATCLAIM_FULFILLED: {
      const claim = action.payload.data;
      claim.claims.forEach((clm, i) => {
        claim.claims[i].resubmissionCode = populateResubmissionCode(clm.typeOfBill);
      });

      if (!claim.claims[0].patient) claim.claims[0].patient = newPersonOrOrg(false);
      if (!claim.claims[0].serviceLocation) claim.claims[0].serviceLocation = newProvider();
      return { ...state, selectedClaim: claim, isEdit: true, invalidClaimId: false };
    }

    case GET_FLATCLAIM_REJECTED:
      return {
        ...newSelectedClaim(),
        invalidClaimId: true,
      };

    case CREATE_CLAIM_REJECTED:
      return {
        ...state,
        submitResult: action.payload.response.data ? action.payload.response.data : 'Unknown error',
      };

    case CREATE_CLAIM_FULFILLED:
      return { ...state, submitResult: action.payload.data };

    case UPDATE_FLATCLAIM_REJECTED:
      return {
        ...state,
        submitResult: action.payload.response.data ? action.payload.response.data : 'Unknown error',
      };

    case UPDATE_FLATCLAIM_FULFILLED:
      return { ...state, submitResult: action.payload.data };

    case UPDATE_MANUAL_CLAIM_ON_EVENTSOURCE:
      return { ...state, selectedClaim: updateSelectedClaim(state.selectedClaim, action.payload) };

    default:
      return childReducer(state, action, nameToProviderMap);
  }
};

export default rootReducer;
