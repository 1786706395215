import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { colors } from '@apps/shared/src/style';
import { PlanMultiplier } from './types/plans';
import { PlanActionTypes } from './types/actions';
import { validArray } from '../../shared/typeChecks';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  deleteButton: {
    color: colors.white,
    backgroundColor: colors.red,
    '&:hover': {
      backgroundColor: colors.redScarlet,
    },
    padding: '0px',
    minWidth: '0px',
  },
});

type ParentProps = {
  sixDegPlanID: number;
  planMultipliers: PlanMultiplier[];
  updatePlanMultipliers: (
    planMultipliers: PlanMultiplier[],
    sixDegPlanID: number
  ) => PlanActionTypes;
  editMode: boolean;
};

type Props = ParentProps;

export default function PlanMultiplierTable({
  sixDegPlanID,
  planMultipliers,
  updatePlanMultipliers,
  editMode,
}: Props): JSX.Element {
  const classes = useStyles();

  const handleDelete = (i: number) => (): void => {
    const newPlanMutlipliers = [...planMultipliers];
    newPlanMutlipliers.splice(i, 1);
    updatePlanMultipliers(newPlanMutlipliers, sixDegPlanID);
  };

  const safeMutlipliers = validArray(planMultipliers);

  return (
    <>
      <Typography>Plan Multipliers:</Typography>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell align="right">Facility</TableCell>
            <TableCell align="right">Professional</TableCell>
            <TableCell align="right">Drug</TableCell>
            <TableCell align="right">Anesthesia</TableCell>
            {editMode ? <TableCell align="right">Delete</TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {safeMutlipliers.map((row, i) => (
            <TableRow key={row.startDate}>
              <TableCell>{row.startDate}</TableCell>
              <TableCell align="right">{row.endDate}</TableCell>
              <TableCell align="right">{row.facilityMultiplier}</TableCell>
              <TableCell align="right">{row.professionalMultiplier}</TableCell>
              <TableCell align="right">{row.drugMultiplier}</TableCell>
              <TableCell align="right">{row.anesthesiaMultiplier}</TableCell>
              {editMode ? (
                <TableCell align="right">
                  <IconButton
                    data-testid={`plan-multiplier-delete-button-${i}`}
                    className={classes.deleteButton}
                    onClick={handleDelete(i)}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
