import React, { useState } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { colors } from '@apps/shared/src/style';
import { RootState } from '../../shared/types/types';
import { addNewPlanAndTPA, removeNewPlanAndTPA } from './plansActions';
import PlanCard from './PlanCard';
import { Plan, PlanMultiplier } from './types/plans';

const useStyles = makeStyles({
  newButton: {
    height: '50px',
    marginRight: '20px',
    paddingLeft: '40px',
    paddingRight: '40px',
    color: colors.white,
    backgroundColor: colors.green,
    '&:hover': {
      backgroundColor: colors.greenMuted,
    },
  },
});

const mapDispatchToProps = {
  addNewPlanAndTPA,
  removeNewPlanAndTPA,
};

type StateProps = {
  plan: Plan;
  censusNames: string[];
  planMultipliers: PlanMultiplier[];
};

const mapStateToProps = ({ plans }: RootState): StateProps => {
  return {
    plan: plans.allPlans[0],
    censusNames: plans.allCensusNames[0],
    planMultipliers: plans.allPlanMultipliers[0],
  };
};

type Props = StateProps & typeof mapDispatchToProps;

function NewPlan({ plan, censusNames, planMultipliers, removeNewPlanAndTPA }: Props): JSX.Element {
  const classes = useStyles();
  const [isNewOpen, setIsNewOpen] = useState(false);

  const handleOpen = (): void => {
    setIsNewOpen(true);
  };

  const handleClose = (): void => {
    setIsNewOpen(false);
    removeNewPlanAndTPA();
  };

  return (
    <>
      <Button className={classes.newButton} onClick={handleOpen}>
        New Plan
      </Button>
      <Dialog open={isNewOpen} onClose={handleClose} maxWidth="md">
        <PlanCard
          isNewPlan
          plan={plan}
          censusNames={censusNames}
          planMultipliers={planMultipliers}
          handleClose={handleClose}
        />
      </Dialog>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPlan);
