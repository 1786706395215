import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { TPA } from './types/plans';

const useStyles = makeStyles({
  planNames: {
    marginBottom: '20px',
  },
});

type ParentProps = {
  planID: string;
  planName: string;
  sixDegTPAID: number;
  sixDegPlanID: number;
  stageName: string;
  startDate: string;
  termDate: string;
  sfOpportunityID: string;
  percentSavings: number;
  send837Invoice?: boolean;
  invoicingType?: string;
  allTPAs: Record<number, TPA>;
};

type Props = ParentProps;

export default function PlanNames({
  planID,
  planName,
  sixDegTPAID,
  sixDegPlanID,
  stageName,
  startDate,
  termDate,
  sfOpportunityID,
  percentSavings,
  invoicingType,
  send837Invoice,
  allTPAs,
}: Props): JSX.Element {
  const classes = useStyles();

  const modifiedTermDate = termDate === '01/01/9999' ? 'Present' : termDate;
  const sfOppIDLink = `https://6degreehealth.lightning.force.com/lightning/r/Opportunity/${sfOpportunityID}/view`;

  return (
    <div className={classes.planNames}>
      <Typography variant="body1" color="textSecondary">
        {allTPAs[sixDegTPAID] ? allTPAs[sixDegTPAID].tpaName : 'Unknown TPA'} - {stageName}
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {startDate} - {modifiedTermDate}
      </Typography>
      <Typography variant="h5" component="h2">
        {planID}:{' '}
        <Link href={sfOppIDLink} target="_blank">
          {planName}
        </Link>
      </Typography>
      <Typography variant="body2" color="textSecondary">
        SixDegPlanID: {sixDegPlanID}
      </Typography>
      {percentSavings > 0 && invoicingType && invoicingType !== '' && send837Invoice && (
        <Typography>
          {`Percent of ${invoicingType.charAt(0).toUpperCase() + invoicingType.slice(1)} Fee: `}{' '}
          {percentSavings}%
        </Typography>
      )}
    </div>
  );
}
