export type PlansState = {
  areAllPlansLoading: boolean;
  allPlans: Record<number, Plan>;
  allTPAs: Record<number, TPA>;
  filteredTPAs: number[];
  allPlanMultipliers: Record<number, PlanMultiplier[]>;
  allCensusNames: Record<number, string[]>;
};

export type AllPlansResponse = {
  plans: Record<number, Plan>;
  planMultipliers: Record<number, PlanMultiplier[]>;
  censusNames: Record<number, string[]>;
  tpas: Record<number, TPA>;
};

export type Plan = {
  sixDegPlanID: number;
  sixDegTPAID: number;
  tpaName: string;
  planName: string;
  planID: string;
  stageName: string;
  indefiniteRunOut: boolean;
  repriceForRunOutPeriod: boolean;
  runOutEndDate: string;
  startDate: string;
  termDate: string;
  sfOpportunityID: string;
  percentSavings: number;
  invoicingType?: string;
  mediviPlanAccess?: string;
  facilityInvoicing?: boolean;
  professionalInvoicing?: boolean;
  isLoading: boolean;
  isError: boolean;
  bypassEligibility: boolean;
  displayMediviMessage: boolean;
  tbcThreshold: boolean;
  threshold: number;
  mediviMessage: string;
  send837Invoice: boolean;
  groupIdOnCard: string;
  runIn: boolean;
};

export type PlanMultiplier = {
  sixDegMultiplierID: number;
  sixDegPlanID: number;
  startDate: string;
  endDate: string;
  facilityMultiplier?: number | string;
  professionalMultiplier?: number | string;
  drugMultiplier?: number | string;
  anesthesiaMultiplier?: number | string;
};

export type TPA = {
  sixDegTPAID: number;
  tpaName: string;
  accountID: string;
};

export type PlanMultiplierField = keyof PlanMultiplier &
  ('facilityMultiplier' | 'professionalMultiplier' | 'drugMultiplier' | 'anesthesiaMultiplier');

export type PlanErrors = {
  tpa: string;
  planID: string;
  planName: string;
  stageName: string;
  startDate: string;
  sfOpportunityID: string;
  percentSavings: string;
  invoiceType: string;
  runOutEndDateErr: string;
  termDateErr: string;
  planRunOutErr: string;
  thresholdErr: string;
  planTermDateErr: string;
};

/* eslint-disable import/prefer-default-export */
export enum StageName {
  livePlan = 'Live Plan',
  planChange = 'Plan Change',
  termed = 'Termed',
  runOut = 'Run Out',
}
export enum MediviPlanAccess {
  fullAccess = 'Full Access',
  limitedAccess = 'Limited Access',
  noAccess = 'No Access',
}
