import { Dispatch } from 'redux';

import axios from 'axios';
import * as p from './types/actions';
import { RootState } from '../../shared/types/types';
import { Plan, PlanMultiplier } from './types/plans';

type PlansDispatch = Dispatch<p.PlanActionTypes>;

export function updatePlan(
  field: keyof Plan,
  value: number | string | boolean,
  sixDegPlanID: number
): p.PlanActionTypes {
  const updateValue = value;
  return {
    type: p.UPDATE_PLAN_INFO,
    meta: {
      field,
      value: updateValue,
      sixDegPlanID,
    },
  };
}

export function updateCensusName(censusNames: string[], sixDegPlanID: number): p.PlanActionTypes {
  return {
    type: p.UPDATE_CENSUS_NAME,
    meta: {
      censusNames,
      sixDegPlanID,
    },
  };
}

export function updatePlanMultipliers(
  planMultipliers: PlanMultiplier[],
  sixDegPlanID: number
): p.PlanActionTypes {
  return {
    type: p.UPDATE_PLAN_MULTIPLIERS,
    meta: {
      planMultipliers,
      sixDegPlanID,
    },
  };
}

export function getPlans(): p.PlanActionTypes {
  return {
    type: p.GET_ALL_PLANS,
    payload: axios.get('/api/planEditor', {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function getPlan(sixDegPlanID: number): p.PlanActionTypes {
  return {
    type: p.GET_PLAN,
    meta: {
      sixDegPlanID,
    },
    payload: axios.get(`/api/planEditor/${sixDegPlanID}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}
export function deletePlan(sixDegPlanID: number): p.PlanActionTypes {
  return {
    type: p.DELETE_PLAN,
    meta: {
      sixDegPlanID,
    },
    payload: axios.delete(`/api/planEditor/${sixDegPlanID}`, {
      headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
    }),
  };
}

export function savePlanUpdates(sixDegPlanID: number) {
  return (dispatch: PlansDispatch, getState: () => RootState): void => {
    const { plans } = getState();
    const { allPlans, allCensusNames, allPlanMultipliers } = plans;
    const newPlan = allPlans[sixDegPlanID];
    const newCensusnames = allCensusNames[sixDegPlanID];
    const newPlanMultipliers = allPlanMultipliers[sixDegPlanID];
    if (!!newPlan.invoicingType && !!newPlan.percentSavings) newPlan.send837Invoice = true;
    else newPlan.send837Invoice = false;
    dispatch({
      type: p.SAVE_PLAN,
      meta: {
        sixDegPlanID,
      },
      payload: axios.post(
        '/api/planEditor',
        { plan: newPlan, censusNames: newCensusnames, planMultipliers: newPlanMultipliers },
        {
          headers: { 'X-CSRF-Token': localStorage.getItem('csrfToken') },
        }
      ),
    });
  };
}

export function setFilteredTPAs(tpaIDs: number[]): p.PlanActionTypes {
  return {
    type: p.SET_FILTERED_TPAS,
    meta: {
      filteredTPAs: tpaIDs,
    },
  };
}

export function addNewPlanAndTPA(): p.PlanActionTypes {
  return {
    type: p.ADD_NEW_PLAN_TPA,
  };
}

export function removeNewPlanAndTPA(): p.PlanActionTypes {
  return {
    type: p.REMOVE_NEW_PLAN_TPA,
  };
}
