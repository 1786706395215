import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';

import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import PlanMultiplierTable from './PlanMultiplierTable';
import PlanMultiplierForm from './PlanMultiplierForm';
import CensusNames from './CensusNames';
import CensusNamesForm from './CensusNamesForm';
import PlanNames from './PlanNames';
import PlanNamesForm from './PlanNamesForm';
import { addSnackbar as addMessage } from '../../shared/components/snackbar/snackbarReducer';
import { RootState } from '../../shared/types/types';
import { TPA, PlanMultiplier, Plan, PlanErrors, StageName } from './types/plans';
import { PlanActionTypes } from './types/actions';
import {
  savePlanUpdates,
  updateCensusName,
  getPlan,
  deletePlan,
  updatePlan,
  updatePlanMultipliers,
  removeNewPlanAndTPA,
} from './plansActions';

const useStyles = makeStyles({
  root: {
    width: '780px',
    margin: '20px',
    minHeight: '350px',
    overflow: 'auto',
  },
  errorCard: {
    textAlign: 'center',
    marginTop: '100px',
  },
  loading: {
    height: '90%',
    alignItems: 'center',
  },
});

type ParentProps = {
  plan: Plan;
  censusNames: string[];
  planMultipliers: PlanMultiplier[];
  handleClose?: () => void;
  isNewPlan: boolean;
};

type StateProps = {
  allTPAs: Record<number, TPA>;
};

const mapStateToProps = ({ plans }: RootState): StateProps => {
  return {
    allTPAs: plans.allTPAs,
  };
};

const mapDispatchToProps = {
  savePlanUpdates,
  getPlan,
  deletePlan,
  updatePlan,
  updateCensusName,
  updatePlanMultipliers,
  addMessage,
  removeNewPlanAndTPA,
};

type DispatchProps = {
  savePlanUpdates: (sixDegPlanID: number) => void;
  getPlan: (sixDegPlanID: number) => void;
  deletePlan: (sixDegPlanID: number) => void;
  updateCensusName: (censusNames: string[], sixDegPlanID: number) => PlanActionTypes;
  updatePlanMultipliers: (
    planMultipliers: PlanMultiplier[],
    sixDegPlanID: number
  ) => PlanActionTypes;
  addMessage: (message: string) => void;
  removeNewPlanAndTPA: () => void;
};

type Props = ParentProps & StateProps & DispatchProps;

export function PlanCard({
  plan,
  censusNames,
  planMultipliers,
  allTPAs,
  savePlanUpdates,
  getPlan,
  deletePlan,
  updateCensusName,
  updatePlanMultipliers,
  addMessage,
  removeNewPlanAndTPA,
  handleClose,
  isNewPlan,
}: Props): JSX.Element | null {
  const classes = useStyles();
  const {
    sixDegPlanID,
    planID,
    planName,
    sixDegTPAID,
    stageName,
    startDate,
    termDate,
    sfOpportunityID,
    bypassEligibility,
    displayMediviMessage,
    tbcThreshold,
    threshold,
    facilityInvoicing,
    professionalInvoicing,
    mediviMessage,
    percentSavings,
    invoicingType,
    mediviPlanAccess,
    isLoading,
    isError,
    send837Invoice,
    groupIdOnCard,
    indefiniteRunOut,
    repriceForRunOutPeriod,
    runOutEndDate,
    runIn,
  } = plan;
  const [editMode, setEditMode] = useState(isNewPlan);
  const [isDeleted, setIsDeleted] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const noErrors: PlanErrors = {
    tpa: '',
    planID: '',
    planName: '',
    sfOpportunityID: '',
    stageName: '',
    startDate: '',
    percentSavings: '',
    invoiceType: '',
    runOutEndDateErr: '',
    termDateErr: '',
    planRunOutErr: '',
    thresholdErr: '',
    planTermDateErr: '',
  };

  const [planErrors, setPlanErrors] = useState(noErrors);

  const handleSave = (): void => {
    const newErrors = { ...noErrors };
    if (!(sfOpportunityID?.length === 18)) {
      newErrors.sfOpportunityID = 'Salesforce Opportunity ID must be 18 characters';
    }
    if (stageName === '') {
      newErrors.stageName = 'StageName must be set';
    }
    if (plan.startDate === '' || plan.startDate === '01/01/0001') {
      newErrors.startDate = 'Must set plan start date';
    }
    if (sixDegTPAID === 0) {
      newErrors.tpa = 'Must set TPA';
    }
    if (planID.length < 3) {
      newErrors.planID = 'Plan ID must be 3 or more characters';
    }
    if (planName.length < 3) {
      newErrors.planName = 'Plan name must be 3 or more characters';
    }
    if (plan.invoicingType !== '' && plan.percentSavings <= 0 && plan.invoicingType !== 'none') {
      newErrors.percentSavings = 'If an 837 invoicing type is chosen this value is required.';
    }
    if (plan.invoicingType === 'billed' || plan.invoicingType === 'savings') {
      if (!plan.professionalInvoicing && !plan.facilityInvoicing) {
        newErrors.invoiceType = '837 Invoicing Requires Claim Type(s) to be Selected.';
        addMessage('837 Invoicing Requires Claim Type(s) to be Selected.');
      }
    }
    if (stageName === StageName.runOut) {
      if (!plan.indefiniteRunOut && !plan.repriceForRunOutPeriod) {
        newErrors.planRunOutErr =
          'When runout stage is selected indefiniterunout and repriceforrunoutperiod is required';
        addMessage('indefiniterunout and repriceforrunoutperiod is required');
      }
    }
    if (plan.repriceForRunOutPeriod) {
      if (plan.runOutEndDate === '') {
        newErrors.runOutEndDateErr = 'Run out end date is required';
      }
    }

    if (
      tbcThreshold &&
      (threshold === 0 || threshold === undefined || threshold === null || Number.isNaN(threshold))
    ) {
      newErrors.thresholdErr = 'TBC Threshold is required';
    }

    if ((stageName === 'Termed' || stageName === 'Plan Change') && termDate === '01/01/9999') {
      addMessage('Plan Term Date Required');
      newErrors.planTermDateErr = 'Plan Term Date Required';
    }

    setPlanErrors(newErrors);
    if (Object.values(newErrors).some(value => value !== '')) return;

    setEditMode(false);
    savePlanUpdates(sixDegPlanID);
    if (handleClose) {
      handleClose();
    }
  };

  const handleCancel = (): void => {
    if (handleClose) {
      handleClose();
    }
    if (isNewPlan) {
      removeNewPlanAndTPA();
    } else {
      setEditMode(false);
      getPlan(sixDegPlanID);
    }
  };
  const handleDelete = (): void => {
    setShowConfirm(true);
  };
  const handleCloseConfirm = (): void => {
    setShowConfirm(false);
  };
  const handleDeletePlan = (): void => {
    setShowConfirm(false);
    setEditMode(false);
    deletePlan(sixDegPlanID);
    setIsDeleted(true);
  };

  if (isLoading) {
    return (
      <Card className={classes.root}>
        <LoadingSpinner containerClassName={classes.loading} />
      </Card>
    );
  }

  if (isError) {
    return (
      <Card className={classes.root}>
        <div className={classes.errorCard}>
          <Typography>The was an error involving this plan...</Typography>
          <IconButton color="secondary" onClick={handleCancel}>
            Reload <RefreshIcon />
          </IconButton>
        </div>
      </Card>
    );
  }

  if (isDeleted) {
    return (
      <Card className={classes.root}>
        <div className={classes.errorCard}>
          <Typography>Plan Deleted successfully</Typography>
          {/* <IconButton color="secondary" onClick={handleCancel}>
            Reload <RefreshIcon />
          </IconButton> */}
        </div>
      </Card>
    );
  }

  if (editMode) {
    return (
      <>
        <Dialog open={showConfirm} onClose={handleCloseConfirm}>
          <DialogTitle>Delete Plan</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete this Plan?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirm}>No</Button>
            <Button onClick={handleDeletePlan}>Yes</Button>
          </DialogActions>
        </Dialog>
        <Card className={classes.root}>
          <CardContent>
            <PlanNamesForm
              sixDegPlanID={sixDegPlanID}
              planID={planID}
              planName={planName}
              sixDegTPAID={sixDegTPAID}
              stageName={stageName}
              indefiniteRunOut={indefiniteRunOut}
              repriceForRunOutPeriod={repriceForRunOutPeriod}
              runOutEndDate={runOutEndDate}
              startDate={startDate}
              termDate={termDate}
              sfOpportunityID={sfOpportunityID}
              bypassEligibility={bypassEligibility}
              displayMediviMessage={displayMediviMessage}
              tbcThreshold={tbcThreshold}
              threshold={threshold}
              facilityInvoicing={facilityInvoicing}
              professionalInvoicing={professionalInvoicing}
              mediviMessage={mediviMessage}
              percentSavings={percentSavings}
              invoicingType={invoicingType}
              mediviPlanAccess={mediviPlanAccess}
              allTPAs={allTPAs}
              planErrors={planErrors}
              setPlanErrors={setPlanErrors}
              handleSnackBar={addMessage}
              groupIdOnCard={groupIdOnCard}
              runIn={runIn}
            />
            <CensusNamesForm
              sixDegPlanID={sixDegPlanID}
              censusNames={censusNames}
              updateCensusName={updateCensusName}
            />
            <PlanMultiplierTable
              sixDegPlanID={sixDegPlanID}
              planMultipliers={planMultipliers}
              updatePlanMultipliers={updatePlanMultipliers}
              editMode={editMode}
            />
            <PlanMultiplierForm
              sixDegPlanID={sixDegPlanID}
              planMultipliers={planMultipliers}
              updatePlanMultipliers={updatePlanMultipliers}
              handleSnackBar={addMessage}
            />
            <CardActions>
              <Button size="large" variant="contained" color="secondary" onClick={handleCancel}>
                Cancel
              </Button>

              <Button size="large" variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
              {stageName === 'Termed' && startDate === termDate && (
                <Button size="large" variant="contained" color="secondary" onClick={handleDelete}>
                  Delete
                </Button>
              )}
            </CardActions>
          </CardContent>
        </Card>
      </>
    );
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <PlanNames
          planID={planID}
          planName={planName}
          sixDegTPAID={sixDegTPAID}
          sixDegPlanID={sixDegPlanID}
          stageName={stageName}
          startDate={startDate}
          termDate={termDate}
          sfOpportunityID={sfOpportunityID}
          percentSavings={percentSavings}
          invoicingType={invoicingType}
          allTPAs={allTPAs}
          send837Invoice={send837Invoice}
        />
        <CensusNames censusNames={censusNames} />
        <PlanMultiplierTable
          sixDegPlanID={sixDegPlanID}
          planMultipliers={planMultipliers}
          updatePlanMultipliers={updatePlanMultipliers}
          editMode={false}
        />
      </CardContent>
      <CardActions>
        <Button
          size="large"
          variant="contained"
          color="secondary"
          onClick={(): void => setEditMode(true)}
        >
          Edit
        </Button>
      </CardActions>
    </Card>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanCard);
