import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';

import { colors } from '@apps/shared/src/style';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import DateInput from '../../manualClaim/DateInput';
import TextInput from '../../shared/components/TextInput';
import { InputChangeHandler } from '../../shared/types/types';
import { checkDate } from '../../shared/validators';
import { updatePlan } from './plansActions';
import { infinityDate } from './plansReducer';
import { PlanErrors, StageName, TPA, MediviPlanAccess } from './types/plans';

const useStyles = makeStyles({
  planNameForm: {
    marginBottom: '25px',
  },
  input: {
    display: 'inline-block',
    marginRight: '20px',
  },
  mediviPlanAccessWrapper: {
    display: 'inline-flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  smallLabel: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  alertText: {
    color: colors.red,
    fontSize: '12px',
  },
  select: {
    minWidth: '120px',
  },
  planNameField: {
    minWidth: '500px',
  },
  resize: {
    fontSize: '.75em',
    marginLeft: '3%',
    marginRight: '3%',
  },
  checkbox: {
    marginRight: '3%',
  },
});

export function verifyPlanDate(
  field: 'startDate' | 'termDate' | 'runOutEndDate',
  value: number | string,
  handleSnackBar: (message: string) => void
): string {
  const [isValid, parsedDate] = checkDate(value, true);
  let newDate = parsedDate;
  if (isValid) return newDate;

  newDate = field === 'startDate' || field === 'runOutEndDate' ? '' : infinityDate;
  if (value !== newDate) handleSnackBar(`Invalid Date: ${value}`);

  return newDate;
}

type ParentProps = {
  sixDegPlanID: number;
  planID: string;
  planName: string;
  sixDegTPAID: number;
  stageName: string;
  indefiniteRunOut: boolean;
  repriceForRunOutPeriod: boolean;
  runOutEndDate: string;
  startDate: string;
  termDate: string;
  sfOpportunityID: string;
  bypassEligibility: boolean;
  tbcThreshold: boolean;
  threshold: number;
  percentSavings: number;
  invoicingType?: string;
  mediviPlanAccess?: string;
  facilityInvoicing?: boolean;
  professionalInvoicing?: boolean;
  displayMediviMessage: boolean;
  mediviMessage: string;
  allTPAs: Record<number, TPA>;
  planErrors: PlanErrors;
  setPlanErrors: React.Dispatch<React.SetStateAction<PlanErrors>>;
  handleSnackBar: (message: string) => void;
  groupIdOnCard: string;
  runIn: boolean;
};

const mapDispatchToProps = {
  updatePlan,
};

type Props = ParentProps & typeof mapDispatchToProps;

export function PlanNamesForm({
  sixDegPlanID,
  planID,
  planName,
  sixDegTPAID,
  stageName,
  indefiniteRunOut,
  repriceForRunOutPeriod,
  runOutEndDate,
  startDate,
  termDate,
  sfOpportunityID,
  bypassEligibility,
  displayMediviMessage,
  tbcThreshold,
  threshold,
  mediviMessage,
  percentSavings,
  invoicingType,
  mediviPlanAccess,
  facilityInvoicing,
  professionalInvoicing,
  allTPAs,
  updatePlan,
  planErrors,
  setPlanErrors,
  handleSnackBar,
  groupIdOnCard,
  runIn,
}: Props): JSX.Element {
  const classes = useStyles();
  const [enablePlanMessage, setEnablePlanMessage] = React.useState(displayMediviMessage);
  const [thresholdValue, setThresholdValue] = React.useState<number>(threshold);
  const [enableTbcThreshold, setEnableTbcThreshold] = React.useState(tbcThreshold || false);
  const [enableFacilityInvoicing, setEnableFacilityInvoicing] = React.useState(facilityInvoicing);
  const [enableStageRunOut, setEnableStageRunOut] = React.useState(true);
  const [enableRunOutPeriod, setEnableRunOutPeriod] = React.useState(repriceForRunOutPeriod);
  const [enableProfessionalInvoicing, setEnableProfessionalInvoicing] =
    React.useState(professionalInvoicing);
  const [invoiceType, setInvoiceType] = React.useState(invoicingType || 'none');
  const [typeSelected, setTypeSelected] = React.useState(!!percentSavings);

  const planTermDate = termDate || infinityDate;

  const handleSFOpportunityIDChange: InputChangeHandler = e => {
    setPlanErrors({ ...planErrors, sfOpportunityID: '' });
    updatePlan('sfOpportunityID', e.target.value, sixDegPlanID);
  };

  const handleStageNameChange = (
    e: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ): void => {
    if (typeof e.target.value === 'string') {
      setEnableStageRunOut(!(e.target.value === 'Termed'));
      setEnableRunOutPeriod(!(e.target.value === 'Termed') && enableRunOutPeriod);
      setPlanErrors({ ...planErrors, runOutEndDateErr: '' });
      updatePlan('stageName', e.target.value, sixDegPlanID);
    }
  };

  const handleMediviPlanAccessChange = (
    e: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ): void => {
    if (typeof e.target.value === 'string') {
      updatePlan('mediviPlanAccess', e.target.value, sixDegPlanID);
    }
  };

  const handlePlanDateChange =
    (field: 'startDate' | 'termDate' | 'runOutEndDate') =>
    (value: number | string): void => {
      const planDate = verifyPlanDate(field, value, handleSnackBar);
      updatePlan(field, planDate, sixDegPlanID);
    };

  const handleTPAIDChange = (
    e: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ): void => {
    if (typeof e.target.value === 'string') {
      const integerID = parseInt(e.target.value, 10);
      setPlanErrors({ ...planErrors, tpa: '' });
      updatePlan('sixDegTPAID', integerID, sixDegPlanID);
    }
  };

  const handlePlanIDChange: InputChangeHandler = e => {
    setPlanErrors({ ...planErrors, planID: '' });
    updatePlan('planID', e.target.value, sixDegPlanID);
  };

  const handleIndefiniteRunOutChange: InputChangeHandler = e => {
    setPlanErrors({ ...planErrors, planID: '' });
    updatePlan('indefiniteRunOut', e.target.checked, sixDegPlanID);
  };

  const handleRepriceRunOutChange: InputChangeHandler = e => {
    setEnableRunOutPeriod(e.target.checked);
    setPlanErrors({ ...planErrors, planID: '' });
    updatePlan('repriceForRunOutPeriod', e.target.checked, sixDegPlanID);
  };

  const handlegGroupIDonCardChange: InputChangeHandler = e => {
    setPlanErrors({ ...planErrors, planID: '' });
    updatePlan('groupIdOnCard', e.target.value, sixDegPlanID);
  };

  const handlePlanNameChange: InputChangeHandler = e => {
    setPlanErrors({ ...planErrors, planName: '' });
    updatePlan('planName', e.target.value, sixDegPlanID);
  };

  const handlePercentSavingsChange: InputChangeHandler = e => {
    let newPercent = parseFloat(e.target.value);
    if (Number.isNaN(newPercent)) newPercent = 0;
    updatePlan('percentSavings', newPercent, sixDegPlanID);
  };

  const toggleEnableBypass: InputChangeHandler = e => {
    updatePlan('bypassEligibility', e.target.checked, sixDegPlanID);
  };

  const toggleEnablerunIn: InputChangeHandler = e => {
    updatePlan('runIn', e.target.checked, sixDegPlanID);
  };

  const toggleEnableMediviMessage: InputChangeHandler = e => {
    setEnablePlanMessage(e.target.checked);
    updatePlan('displayMediviMessage', e.target.checked, sixDegPlanID);
  };

  const toggleEnableTbcThreshold: InputChangeHandler = e => {
    setEnableTbcThreshold(e.target.checked);
    updatePlan('tbcThreshold', e.target.checked, sixDegPlanID);
  };

  const toggleEnableFacility: InputChangeHandler = e => {
    setEnableFacilityInvoicing(e.target.checked);
    updatePlan('facilityInvoicing', e.target.checked, sixDegPlanID);
  };

  const toggleEnableProfessional: InputChangeHandler = e => {
    setEnableProfessionalInvoicing(e.target.checked);
    updatePlan('professionalInvoicing', e.target.checked, sixDegPlanID);
  };

  const handleMediviMessageChange: InputChangeHandler = e => {
    updatePlan('mediviMessage', e.target.value, sixDegPlanID);
  };

  const handleTbcThresholdChange: InputChangeHandler = e => {
    if (enableTbcThreshold) {
      const input = e.target.value;
      const numbersOnly = input.replace(/\D/g, ''); // Remove non-numeric characters
      const truncatedValue = numbersOnly.slice(0, 8);

      setPlanErrors({ ...planErrors, thresholdErr: '' });
      setThresholdValue(truncatedValue);

      updatePlan('threshold', parseInt(truncatedValue, 10), sixDegPlanID);
    }
  };

  const handle837InvoicingSelect = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const newValue = event.target.value as string;
    setInvoiceType(newValue);
    if (event.target?.value === 'none') {
      setTypeSelected(false);
      updatePlan('invoicingType', '', sixDegPlanID);
      updatePlan('percentSavings', 0, sixDegPlanID);
      updatePlan('facilityInvoicing', false, sixDegPlanID);
      updatePlan('professionalInvoicing', false, sixDegPlanID);
    } else {
      setTypeSelected(true);
      updatePlan('invoicingType', newValue, sixDegPlanID);
    }
  };

  type InvoiceType = {
    label: string;
    value: string;
  };

  type InvoiceOptionType = InvoiceType[];

  const invoiceOptions: InvoiceOptionType = [
    {
      label: 'None',
      value: 'none',
    },
    {
      label: '% of Savings',
      value: 'savings',
    },
    {
      label: '% of Billed',
      value: 'billed',
    },
  ];

  return (
    <div className={classes.planNameForm}>
      <div className={classes.input}>
        <div className={classes.input}>
          <TextInput
            label="SF Opportunity ID"
            aria-label="SF Opportunity ID"
            value={sfOpportunityID}
            maxLength={18}
            allowedCharRegex="a-zA-Z0-9"
            onChange={handleSFOpportunityIDChange}
            errorLabel={planErrors.sfOpportunityID}
          />
        </div>
        <FormControl>
          <InputLabel>StageName</InputLabel>
          <Select
            className={classes.select}
            autoWidth
            onChange={handleStageNameChange}
            value={stageName}
          >
            <MenuItem value={StageName.livePlan}>Live Plan</MenuItem>
            <MenuItem value={StageName.planChange}>Plan Change</MenuItem>
            <MenuItem value={StageName.termed}>Termed</MenuItem>
            <MenuItem value={StageName.runOut}>Run Out</MenuItem>
          </Select>
          <Typography variant="body1" className={classes.alertText}>
            {planErrors.stageName}
          </Typography>
        </FormControl>
        {stageName === StageName.runOut && (
          <span>
            <FormControlLabel
              control={
                <Checkbox checked={indefiniteRunOut} onChange={handleIndefiniteRunOutChange} />
              }
              label="Indefinitely reprice claims with DOS within contracted period"
              aria-label="Indefinitely reprice claims with DOS within contracted period"
            />
            <FormControlLabel
              control={
                <Checkbox checked={repriceForRunOutPeriod} onChange={handleRepriceRunOutChange} />
              }
              label="Reprice claims according to run out period"
              aria-label="Reprice claims according to run out period"
            />
          </span>
        )}
      </div>
      <div>
        <div className={classes.input}>
          <DateInput
            className={classes.input}
            name="planStartDate"
            label="Plan Start Date"
            aria-label="Plan Start Date"
            onValueChange={handlePlanDateChange('startDate')}
            value={startDate}
            errorLabel={planErrors.startDate}
          />
        </div>
        <div className={classes.input}>
          <DateInput
            error={planErrors.termDateErr !== ''}
            helperText={planErrors.termDateErr}
            className={classes.input}
            name="planTermDate"
            label="Plan Term Date"
            aria-label="Plan Term Date"
            onValueChange={handlePlanDateChange('termDate')}
            value={planTermDate}
          />
        </div>

        {enableRunOutPeriod && stageName === StageName.runOut && (
          <DateInput
            className={classes.input}
            name="runOutEndDate"
            label="Run Out End Date"
            aria-label="Run Out End Date"
            onValueChange={handlePlanDateChange('runOutEndDate')}
            value={runOutEndDate}
            errorLabel={planErrors.runOutEndDateErr}
          />
        )}
      </div>
      <div>
        <div className={classes.input}>
          <FormControl>
            <InputLabel>TPA</InputLabel>
            <Select
              className={classes.select}
              autoWidth
              onChange={handleTPAIDChange}
              value={allTPAs[sixDegTPAID] ? sixDegTPAID : ''}
            >
              {Object.entries(allTPAs).map(([sixDegTPAID, tpa]) => (
                <MenuItem key={sixDegTPAID} value={sixDegTPAID}>
                  {tpa.tpaName}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="body1" className={classes.alertText}>
              {planErrors.tpa}
            </Typography>
          </FormControl>
        </div>
        <div className={classes.input}>
          <TextInput
            label="PlanID"
            aria-label="PlanID"
            value={planID}
            onChange={handlePlanIDChange}
            errorLabel={planErrors.planID}
          />
        </div>
        <div className={classes.input}>
          <TextInput
            label="Group ID on Card"
            aria-label="Group ID on Card"
            maxLength={24}
            allowedCharRegex="a-zA-Z0-9"
            value={groupIdOnCard}
            onChange={handlegGroupIDonCardChange}
          />
        </div>
        <div className={classes.input}>
          <TextInput
            label="Plan Name"
            aria-label="Plan Name"
            value={planName}
            onChange={handlePlanNameChange}
            errorLabel={planErrors.planName}
            className={classes.planNameField}
          />
        </div>
        <br />
        <div className={`${classes.input} ${classes.mediviPlanAccessWrapper}`}>
          <FormControl>
            <InputLabel>837 Invoicing </InputLabel>
            <Select
              className={classes.select}
              autoWidth
              onChange={handle837InvoicingSelect}
              value={invoiceType}
            >
              {invoiceOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="body1" className={classes.alertText}>
              {planErrors.invoiceType}
            </Typography>
          </FormControl>
          <FormControl>
            <InputLabel>Medivi Access</InputLabel>
            <Select
              className={classes.select}
              autoWidth
              onChange={handleMediviPlanAccessChange}
              value={mediviPlanAccess}
              defaultValue="Full Access"
            >
              <MenuItem value="Full Access">Full Access</MenuItem>
              <MenuItem value="Limited Access">Limited Access</MenuItem>
              <MenuItem value="No Access">No Access</MenuItem>
            </Select>
          </FormControl>
        </div>
        {typeSelected && (
          <div className={classes.input}>
            <TextInput
              label={`Percent of ${invoiceType.charAt(0).toUpperCase() + invoiceType.slice(1)} Fee`}
              aria-label={`Percent of ${
                invoiceType.charAt(0).toUpperCase() + invoiceType.slice(1)
              } Fee`}
              value={percentSavings}
              errorLabel={planErrors.percentSavings}
              isPercent
              onChange={handlePercentSavingsChange}
            />
          </div>
        )}
        <div className={classes.input}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={bypassEligibility} onChange={toggleEnableBypass} />}
              label="Bypass Eligibility"
              aria-label="Bypass Eligibility"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={enablePlanMessage}
                  value={enablePlanMessage}
                  onChange={toggleEnableMediviMessage}
                />
              }
              label="Display MediVI message"
              aria-label="Display MediVI message"
            />
            <TextField
              style={{
                border: 'thin solid black',
                borderRadius: '3%',
                width: '120%',
                fontSize: '0.75em',
                display: enablePlanMessage ? '' : 'none',
              }}
              id="outlined-multiline-static"
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              multiline
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{ maxLength: 150 }}
              rows={4}
              onChange={handleMediviMessageChange}
              defaultValue={mediviMessage}
            />
            <FormControlLabel
              control={<Checkbox checked={runIn} value={runIn} onChange={toggleEnablerunIn} />}
              label="Run In"
              aria-label="Run In"
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableTbcThreshold}
                    value={enableTbcThreshold}
                    onChange={toggleEnableTbcThreshold}
                  />
                }
                label="Coding Review Threshold"
                aria-label="Coding Review Threshold"
              />
              {enableTbcThreshold && (
                <TextField
                  error={planErrors.thresholdErr !== ''}
                  helperText={planErrors.thresholdErr}
                  onChange={handleTbcThresholdChange}
                  type="text"
                  value={thresholdValue}
                  variant="standard"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              )}
            </div>
          </FormGroup>
        </div>

        {(invoiceType === 'savings' || invoiceType === 'billed') && (
          <div className={classes.input}>
            <FormControlLabel
              label="Facility"
              aria-label="Facility"
              control={
                <Checkbox
                  checked={enableFacilityInvoicing}
                  value={enableFacilityInvoicing}
                  onChange={toggleEnableFacility}
                />
              }
            />
            <FormControlLabel
              label="Professional"
              aria-label="Professional"
              control={
                <Checkbox
                  checked={enableProfessionalInvoicing}
                  value={enableProfessionalInvoicing}
                  onChange={toggleEnableProfessional}
                />
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default connect(null, mapDispatchToProps)(PlanNamesForm);
