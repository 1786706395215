import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { colors } from '@apps/shared/src/style';
import TextInput from '../../shared/components/TextInput';
import { InputChangeHandler } from '../../shared/types/types';
import { PlanActionTypes } from './types/actions';
import CensusNames from './CensusNames';

const useStyles = makeStyles({
  censusNameForm: {
    marginBottom: '25px',
  },
  chipBox: {
    marginBottom: '25px',
  },
  censusChip: {
    margin: '5px',
  },
  addButton: {
    backgroundColor: colors.green,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.greenMuted,
    },
    padding: '5px',
    margin: '10px',
    minWidth: '0px',
  },
});

type ParentProps = {
  censusNames: string[];
  updateCensusName: (censusNames: string[], sixDegPlanID: number) => PlanActionTypes;
  sixDegPlanID: number;
};

type Props = ParentProps;

export default function CensusNamesForm({
  censusNames,
  updateCensusName,
  sixDegPlanID,
}: Props): JSX.Element {
  const classes = useStyles();

  const [newCensusName, setNewCensusName] = useState('');
  const [errorText, setErrorText] = useState('');

  const handleCensusNameUpdate: InputChangeHandler = e => {
    setErrorText('');
    setNewCensusName(e.target.value);
  };

  const handleAddClick = (): void => {
    if (newCensusName.length < 1) {
      setErrorText('Census name must not be empty');
      return;
    }
    if (censusNames.some(name => name === newCensusName)) {
      setErrorText('No duplicate census names allowed');
      return;
    }
    const newCensusNames = [...censusNames];
    if (!newCensusNames.includes(newCensusName)) {
      newCensusNames.push(newCensusName);
    }
    updateCensusName(newCensusNames, sixDegPlanID);
    setNewCensusName('');
  };

  const handleRemoveClick = (index: number) => (): void => {
    const newCensusNames = [...censusNames];
    newCensusNames.splice(index, 1);
    updateCensusName(newCensusNames, sixDegPlanID);
  };

  return (
    <div className={classes.censusNameForm}>
      <CensusNames censusNames={censusNames} handleRemoveClick={handleRemoveClick} />
      <Typography>Add Census Name:</Typography>
      <TextInput
        label="Census Name"
        aria-label="Census Name"
        value={newCensusName}
        errorLabel={errorText}
        onChange={handleCensusNameUpdate}
      />
      <IconButton
        className={classes.addButton}
        onClick={handleAddClick}
        data-testid="add-census-name-button"
      >
        <AddCircleIcon />
      </IconButton>
    </div>
  );
}
